import React from "react";
import Horoscope from '../../../assets/Homepageicon/horoscope.png';
import Weather from '../../../assets/Homepageicon/meteorology.png';
import card from '../../../assets/Homepageicon/id-card.png';


const Extrafeatures = () => {
  return (
    <div className="mt-6 mb-10 flex items-center justify-center ">
      <div className="bg-gray-200/30 sm:w-[540px]  w-[360px]  mx-8 rounded-xl mb-0">
        <h1 className="flex justify-center mt-8 font-bold font-roboto sm:text-xl text-lg mx-2 text-center">
        Explore More with Smart Extras      
          </h1>

        <div className=" mt-16 mb-8">

        <div className=" flex flex-col items-center  px-1 text-center mt-0">
  <img src={Horoscope} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Horoscope</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Discover your horoscope check your daily horoscope for insights and guidance.

  </p>
</div>

<div className=" flex flex-col items-center  px-1 text-center mt-4">
  <img src={Weather} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Weather</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Stay updated with weather get real-time weather updates to plan your day effectively.

  </p>
</div>



<div className=" flex flex-col items-center  px-1 text-center mt-4">
  <img src={card} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Business Card</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Create your digital business card design and share your professional business card effortlessly. 

  </p>
</div>



        </div>
      </div>
    </div>
  );
};

export default Extrafeatures;
