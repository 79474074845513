import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
import { CircularProgress } from '@mui/material'; 
import { toast, ToastContainer, Slide } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideoModal from "../../UserProfile/Smscreen/vidsmodal/VidsModal";

const Savedvidsgrid = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasShownToast, setHasShownToast] = useState(false);

  useEffect(() => {
    const fetchSavedVideos = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token not found in local storage');
        setLoading(false);
        return;
      }

      try {
        const config = {
          method: 'get',
          url: `${API_URL}/vids/v2/bookmark?page_number=${page}`,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        setLoadingMore(true); 

        const response = await axios.request(config);
        const newVids = response.data.data;


        setVideos(prevVids => [...prevVids, ...newVids]);
        setHasMore(newVids.length > 0);


        if (newVids.length > 0) {
        setHasShownToast(false);
      }

    } catch (error) {
      console.error('Error fetching vids:', error);
    } finally {
      setLoading(false); 
      setLoadingMore(false); 
    }
  };

    fetchSavedVideos();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2;

    
      if (isAtBottom) {
      
        if (hasMore && !loadingMore) {
          setPage(prevPage => prevPage + 1);
        } 
       
        else if (!hasMore && videos.length > 0 && !hasShownToast) {
          toast.info('No More Vids to load.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            transition: Slide, 
            theme: "colored",
          });
          setHasShownToast(true); 
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loadingMore, videos.length, hasShownToast]); 

  const openModal = (video) => {
    setSelectedVideo(video);
    localStorage.setItem('vidsId', video.id);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  if (loading) {
    return (
      <div className="mt-24 flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <ToastContainer />
      <div className="relative mt-8">
        {videos.length > 0 ? (
          <div className="grid grid-cols-3 gap-4">
            {videos.map((video) => (
              <div
                key={video.id}
                className="relative flex bg-black/20 sm:w-48 sm:h-72 w-32 h-46 rounded-2xl cursor-pointer hover:scale-105 ease-in-out duration-500"
                onClick={() => openModal(video)}
                
                >
                <img
                  src={`${PROFILEPHOTO_URL}/${video.thumbnail}`}
                  alt="Video Thumbnail"
                  className="object-cover w-full h-full rounded-lg"
                />
                <div className="text-white absolute bottom-0 left-0 mb-4 ml-2 flex items-center">
                  <div className="relative flex items-center justify-center">
                    <PlayArrowIcon style={{ color: 'white' ,fontSize: 38 }} />
                    <span className="text-white text-md font-bold ml-1">{video.viewCount}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center h-96 w-full">
            <div className="text-gray-400 text-center">No saved videos available</div>
          </div>
        )}
      </div>
      {loadingMore && (
            <div className="mt-8 mb-12 flex justify-center">
              <CircularProgress />
            </div>
          )}
      {selectedVideo && (
      <VideoModal video={selectedVideo} onClose={closeModal} />
    )}
    </>
  );
};

export default Savedvidsgrid;
