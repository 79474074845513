import React, { useState, useEffect, useRef } from 'react';
import { fetchvidscommentData } from '../../userapi';
import { API_URL, PROFILEPHOTO_URL } from '../../../../../Config/config';
import { calculateTimeAgo } from '../Utils/timeutils';
import VideoSubmitComment from './vidssubmitcomment';
import VideoCaption from './vidscaption';
import { LikedIcon,  LikeIcon, DislikeIcon, DislikedIcon } from '../Postmodal/postcommenticon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import CommentskeletonList from '../Postmodal/commentskelton';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'; 
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';


const VideoComment = ({ video, handleProfile }) => {
    const [visibleReplies, setVisibleReplies] = useState({});
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [showDropdown, setShowDropdown] = useState(null);
    const [showDropdown1, setShowDropdown1] = useState(null);
    const [showDropdown2, setShowDropdown2] = useState(null);
    const [showDropdown3, setShowDropdown3] = useState(null);
    const [activeReply, setActiveReply] = useState(null);
    const [replyingToUser, setReplyingToUser] = useState(null);
    const [loadingReplies, setLoadingReplies] = useState({});
    const [editingComment, setEditingComment] = useState(null);
    const [editingContent, setEditingContent] = useState('');
    const [editingReply, setEditingReply] = useState(null);
    const [editingReplyContent, setEditingReplyContent] = useState('');
    const containerRef = useRef();
    const [reportCategories, setReportCategories] = useState([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');

    useEffect(() => {
        if (video && video.createdAt) {
            const formattedTime = calculateTimeAgo(video.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [video]);

    useEffect(() => {
        const fetchComments = async () => {
          try {
            const token = localStorage.getItem('token');
            const vidsId = localStorage.getItem('vidsId');
    
            if (!token || !vidsId) {
              throw new Error('Token or postId not found in localStorage');
            }
    
            const config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${API_URL}/vids/v2/comment/${vidsId}?page_number=${page}`,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            };
    
            const response = await axios.request(config);
            const newComments = response.data.data.map(comment => ({
              ...comment,
              replies: comment.replies || []
            }));
    
            if (newComments.length === 0) {
              setHasMore(false);
            }
    
            setComments(prevComments => [...prevComments, ...newComments]);
            setLoading(false);
          } catch (error) {
            setError('Error fetching comments: ' + error.message);
            setLoading(false);
          }
        };
    
        fetchComments();
      }, [page]);

      useEffect(() => {
        const observer = new IntersectionObserver(
          entries => {
            const target = entries[0];
            if (target.isIntersecting && hasMore && !loading) {
              setPage(prevPage => prevPage + 1);
            }
          },
          { threshold: 0.5 }
        );
    
        if (containerRef.current) {
          observer.observe(containerRef.current);
        }
    
        return () => observer.disconnect();
      }, [hasMore, loading]);

      const addNewComment = (newComment) => {
        const commentData = newComment[0] ? newComment[0] : newComment;
        setComments(prevComments => [commentData, ...prevComments]);
      };

      const handleLikeComment = async (commentId) => {
        try {
          const token = localStorage.getItem('token');
          
          const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}/vids/v2/like/comment/${commentId}`,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: {} 
          };
    
          await axios.request(config);
    
          const updatedComments = comments.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likeCount: comment.isCommentLikedByUser ? comment.likeCount - 1 : comment.likeCount + 1,
                dislikeCount: comment.isCommentDislikedByUser ? comment.dislikeCount - 1 : comment.dislikeCount,
                isCommentLikedByUser: !comment.isCommentLikeBydUser,
                isCommentDislikedByUser: false
              };
            }
            return comment;
          });
    
          setComments(updatedComments);
        } catch (error) {
          console.error('Error liking/unliking comment:', error);
        }
      };

      const handleDislikeComment = async (commentId) => {
        try {
          const token = localStorage.getItem('token');
          
          const config = {
            method: 'post',
            url: `${API_URL}/vids/v2/dislike/comment/${commentId}`,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: {} 
          };
    
          await axios.request(config);
    
          const updatedComments = comments.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likeCount: comment.isCommentLikedByUser ? comment.likeCount - 1 : comment.likeCount,
                dislikeCount: comment.isCommentDislikedByUser ? comment.dislikeCount - 1 : comment.dislikeCount + 1,
                isCommentLikedByUser: false,
                isCommentDislikedByUser: !comment.isCommentDislidBkeyUser
              };
            }
            return comment;
          });
    
          setComments(updatedComments);
        } catch (error) {
          console.error('Error disliking/undisliking comment:', error);
        }
      };

      const handleDeleteComment = async (commentId) => {
        try {
          const token = localStorage.getItem('token');
          
          const config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${API_URL}/vids/v2/comment/${commentId}`,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
          const response = await axios.request(config);
      
          setComments(prevComments => {
            const updatedComments = prevComments.filter(comment => comment._id !== commentId);
            return updatedComments;
          });
          setShowDropdown(null);
          toast.success(response.data.msg || 'Comment deleted successfully');

        } catch (error) {
          console.error('Error deleting comment:', error);
          toast.error(error.response?.data?.msg || 'Error deleting comment');

        }
      };

      const toggleDropdown = (index) => {
        setShowDropdown(prev => (prev === index ? null : index));
      };

      const onClose = () => {
        setShowDropdown(null);
      };

      const handleReplySubmit = (commentId, newReply) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const firstName = userData.firstName || 'Default';
        const lastName = userData.lastName || 'User';
        const profilePhoto = userData.profliePhoto;
      
        const formattedReply = {
          ...newReply[0],
          user: {
            firstName,
            lastName,
            profilePhoto
          },
          hasCommentEditPermission: true,
          hasCommentRemovePermission: true
        };
      
        setComments(prevComments =>
          prevComments.map(comment =>
            comment._id === commentId
              ? {
                  ...comment,
                  replies: [...(comment.replies || []), formattedReply], 
                  replyCount: comment.replyCount + 1,
                 
                }
              : comment
          )
        );
      
        setActiveReply(null);
        setReplyingToUser(null);
      };

      const handleViewRepliesClick = async (commentId) => {
        setVisibleReplies(prev => ({
          ...prev,
          [commentId]: !prev[commentId] 
        }));
      
        if (!visibleReplies[commentId]) {
          setLoadingReplies(prev => ({
            ...prev,
            [commentId]: true  
          }));
      
          const replies = await fetchAllRepliesForComment(commentId);
      
          setComments(prevComments =>
            prevComments.map(comment =>
              comment._id === commentId
                ? { ...comment, replies }
                : comment
            )
          );
      
          setLoadingReplies(prev => ({
            ...prev,
            [commentId]: false 
          }));
        }
      };

      const fetchAllRepliesForComment = async (commentId) => {
        try {
          const token = localStorage.getItem('token');
          let allReplies = [];
          let page_number = 0;
          let moreReplies = true;
      
          while (moreReplies) {
            const config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${API_URL}/vids/v2/replies/${commentId}?page_number=${page_number}`,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            };
      
            const response = await axios.request(config);
            const replies = response.data.data;
            
            if (replies.length > 0) {
              allReplies = allReplies.concat(replies);
              page_number++;
            } else {
              moreReplies = false;
            }
          }
      
          return allReplies;
        } catch (error) {
          console.error('Error fetching replies:', error);
          return [];
        }
      };
      
      const toggleDropdown1 = (index) => {
        setShowDropdown1(prev => (prev === index ? null : index));
      };

      const onClose1 = () => {
        setShowDropdown1(null);
      };     
      
      const handleDeleteReply = async (replyId, commentId) => {


        try {
          const token = localStorage.getItem('token');
          
          const config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${API_URL}/vids/v2/replies/${replyId}`,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
          
          const response = await axios.request(config);
          
          setComments(prevComments =>
            prevComments.map(comment => {
              if (comment._id === commentId) {
                const updatedReplies = comment.replies.filter(reply => reply._id !== replyId);
                return {
                  ...comment,
                  replies: updatedReplies,
                  replyCount: updatedReplies.length 
                };
              }
              return comment;
            })
          );
          toast.success(response.data.msg || 'Reply deleted successfully');

        } catch (error) {
          console.error('Error deleting reply:', error);
          toast.error(error.response?.data?.msg || 'Error deleting reply');

        }
      };

      const handleLikeReply = async (replyId, commentId) => {
        try {
          const token = localStorage.getItem('token');
          
          const config = {
            method: 'post',
            url: `${API_URL}/vids/v2/like/replies/${replyId}`,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: {} 
          };
      
          await axios.request(config);
      
          const updatedComments = comments.map(comment  =>{
            if (comment._id === commentId) {
              const updatedReplies = comment.replies.map(reply => {
                if (reply._id === replyId) {
                  return {
                    ...reply,
                    likeCount: reply.isCommentLikedByUser ? reply.likeCount - 1 : reply.likeCount + 1,
                    dislikeCount: reply.isCommentDislikedByUser ? reply.dislikeCount - 1 : reply.dislikeCount,
                    isCommentLikedByUser: !reply.isCommentLikedByUser,
                    isCommentDislikedByUser: false 
                  };
                }
                return reply;
              });
              return {
                ...comment,
                replies: updatedReplies
              };
            }
            return comment;
          });
      
          setComments(updatedComments);
        } catch (error) {
          console.error('Error liking/unliking reply:', error);
        }
      };
      
      const handleDislikeReply = async (replyId, commentId) => {
        try {
          const token = localStorage.getItem('token');
          
          const config = {
            method: 'post',
            url: `${API_URL}/vids/v2/dislike/replies/${replyId}`,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: {} 
          };
      
          await axios.request(config);
      
          const updatedComments = comments.map(comment => {
            if (comment._id === commentId) {
              const updatedReplies = comment.replies.map(reply => {
                if (reply._id === replyId) {
                  return {
                    ...reply,
                    likeCount: reply.isCommentLikedByUser ? reply.likeCount - 1 : reply.likeCount,
                    dislikeCount: reply.isCommentDislikedByUser ? reply.dislikeCount - 1 : reply.dislikeCount + 1,
                    isCommentLikedByUser: false, 
                    isCommentDislikedByUser: !reply.isCommentDislikedByUser
                  };
                }
                return reply;
              });
              return {
                ...comment,
                replies: updatedReplies
              };
            }
            return comment;
          });
      
          setComments(updatedComments);
        } catch (error) {
          console.error('Error disliking/undisliking reply:', error);
        }
      };

      const handleReplyClick = (commentId, firstName, lastName) => {
        setActiveReply(commentId);
        setReplyingToUser(`${firstName} ${lastName}`);
        setEditingComment(null);
        setEditingContent('');
        setEditingReply(null); 
        setEditingReplyContent(''); 
      };

      const handleCancelReply = () => {
        setActiveReply(null);
        setReplyingToUser(null);
       
      };
     
      const handleEditComment = (commentId, content) => {
        setEditingComment(commentId);
        setEditingContent(content);
        setShowDropdown(null);
        setActiveReply(null);
        setReplyingToUser(null);
        setEditingReply(null); 
        setEditingReplyContent(''); 
      };
    
      const handleEditSubmit = (updatedComment) => {
        const commentData = Array.isArray(updatedComment) ? updatedComment[0] : updatedComment;
        setComments(prevComments =>
          prevComments.map(comment =>
            comment._id === editingComment ? { ...comment, ...commentData } : comment
          )
        );
        setEditingComment(null);
        setEditingContent('');
      };
    
      
      const handleCancelEdit = () => {
        setEditingComment(null);
        setEditingContent('');
      };
    
      const handleEditReplyClick = (replyId, content) => {
        setEditingReply(replyId);
        setEditingReplyContent(content);
        setShowDropdown1(null); 
        setActiveReply(null);
        setReplyingToUser(null);
        setEditingComment(null);
        setEditingContent('');
      };
    
      const handleReplyEditSubmit = (updatedReply) => {
        const replyData = Array.isArray(updatedReply) ? updatedReply[0] : updatedReply;
        
        setComments(prevComments =>
          prevComments.map(comment =>
            
            comment._id === replyData.commentId
              ? {
                  ...comment,
                  replies: comment.replies.map(reply =>
                    reply._id === updatedReply._id ? { ...reply, ...replyData } : reply
                  )
                }
              : comment

              
          )
          
        );
        setEditingReply(null);
        setEditingReplyContent('');
      };
      
    
      
      const handleCancelEditReply = () => {
        setEditingReply(null); 
        setEditingReplyContent(''); 
      };

      const toggleDropdown2 = async (index) => {
        setShowDropdown2(prev => (prev === index ? null : index));
        setShowDropdown(null);
      
        if (showDropdown2 !== index) {
          setIsLoadingCategories(true);
      
          const token = localStorage.getItem('token');
          const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}/report/v2/categories`,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
      
          try {
            const response = await axios.request(config);
            setReportCategories(response.data.data);
            setIsLoadingCategories(false);
          } catch (error) {
            console.error('Error fetching report categories:', error);
            setIsLoadingCategories(false);
          }
        }
      };

      const handleReportComment = async (commentId, categoryId) => {
        if (!categoryId) {
          console.error('No category selected');
          return;
        }
      
        const data = JSON.stringify({ report_category_id: categoryId });
      
        const token = localStorage.getItem('token');
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/vids/v2/report/comment/${commentId}`,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };
      
        try {
          const response = await axios.request(config);
          onClose2();
          toast.success(response.data.msg || 'Comment Reported successfully');

        } catch (error) {
          console.error('Error reporting comment:', error);
          toast.error(error.response?.data?.msg || 'Error Reporting comment');

        }
      };

      const onClose2 = () => {
        setShowDropdown2(null);
      };

      const toggleDropdown3 = async (index) => {
        setShowDropdown3(prev => (prev === index ? null : index));
      
      
        if (showDropdown3 !== index) {
          setIsLoadingCategories(true);
      
          const token = localStorage.getItem('token');
          const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}/report/v2/categories`,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
      
          try {
            const response = await axios.request(config);
            setReportCategories(response.data.data);
            setIsLoadingCategories(false);
          } catch (error) {
            console.error('Error fetching report categories:', error);
            setIsLoadingCategories(false);
          }
        }
      };

      const handleReportReply = async (replyId, categoryId) => {
        if (!categoryId) {
          console.error('No category selected');
          return;
        }
      
        const data = JSON.stringify({ report_category_id: categoryId });
      
        const token = localStorage.getItem('token');
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/vids/v2/report/replies/${replyId}`,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };
      
        try {
          const response = await axios.request(config);
          onClose3();
          toast.success(response.data.msg || 'Reply Reported successfully');

        } catch (error) {
          console.error('Error reporting comment:', error);
          toast.error(error.response?.data?.msg || 'Error Reporting reply');

        }
      };

      const onClose3 = () => {
        setShowDropdown3(null);
        setShowDropdown1(null);
      };

  if (loading && comments.length === 0) {
    return <div > <CommentskeletonList /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

    return (
       <div>
                <div className=" h-[510px] overflow-y-auto bg-gray-900/50 overflow-hidden custom-scrollbar">
             <VideoCaption video={video} handleProfile={handleProfile} />
             {comments.map((comment, index) => {
          const commentUser = comment.user || {};
          const firstName = commentUser.firstName || userData.firstName;
          const lastName = commentUser.lastName || userData.lastName;
          const profilePhoto = commentUser.profilePhoto || userData.profilePhoto;
          const isLikedByUser = comment.isCommentLikedByUser;
          const isDislikedByUser = comment.isCommentDislikedByUser;
          const hasRemovePermission = comment.hasCommentRemovePermission || false;
          const hasCommentEditPermission = comment.hasCommentEditPermission || false;
          const hasCommentReportPermission= comment.hasCommentReportPermission || false;

          const isEdited = comment.isEdited || false;

          return (
            <div className="relative" key={index}>
              <div className="flex items-start">
                <div className="w-10 h-10 bg-white rounded-full overflow-hidden mt-4 ml-4 flex-shrink-0 cursor-pointer" onClick={() => handleProfile(comment.user.userId)}>
                  {profilePhoto && (
                    <img
                      src={`${PROFILEPHOTO_URL}/${profilePhoto}`}
                      alt="Profile"
                      className="object-cover w-full h-full rounded-full"
                    />
                  )}
                </div>

                <div className="flex relative flex-col justify-start mx-3 mr-0 mt-4 " >
                  <span className="text-white  "  >
                    <span className="text-white cursor-pointer " onClick={() => handleProfile(comment.user.userId)}>
                    {firstName.length + lastName.length > 15 ? (
                      firstName.length > 15 ? (
                        <span className="truncate text-white">
                          {`${firstName.slice(0, 12)}...`} 
                        </span>
                      ) : (
                        <span className="truncate text-white">
                          {`${firstName} ${lastName.slice(0, 15 - firstName.length - 1)}... `}
                        </span>
                      )
                    ) : (
                      <span className="truncate tex-twhite">
                        {`${firstName} ${lastName} `}
                      </span>
                    )}
                    </span>
                    <span className="pl-0 mx-0 text-sm text-white/80" >
                      {comment.content}
                    </span>
                  </span>

                  <div className='text-gray-400 text-xs flex ml-0 '>
                    {calculateTimeAgo(comment.createdAt)}
                    <div className='text-gray-400 text-xs ml-3 flex'>
                     {comment.likeCount > 1 ? `${comment.likeCount} likes` : `${comment.likeCount} like`}
                     <div className='mx-2'>
                     {comment.dislikeCount > 1 ? `${comment.dislikeCount} dislikes` : `${comment.dislikeCount} dislike`}
                     </div>
                     
                     </div>

                    <div className="text-gray-400 text-xs ml-2 cursor-pointer" onClick={() => handleReplyClick(comment._id, firstName, lastName)} >
                      Reply
                    </div>    
                    {isEdited && ( 
                    <div className="text-gray-400 txet-xs ml-3 ">
                      Edited
                      </div>  
                      )}            
                    <div className='mt-0 flex items-center  ml-3 cursor-pointer' onClick={() => toggleDropdown(index)} >
                    {(hasCommentEditPermission || hasRemovePermission || hasCommentReportPermission) && (
                         <MoreHorizIcon style={{ fontSize: 20 }} />
                     )}
                    </div>
                    {showDropdown === index && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center">
                      <div className="fixed inset-0 bg-black/50 " ></div>
                      <div className="z-50 bg-neutral-800 w-80  text-lg  rounded-2xl shadow-lg text-white flex flex-col items-center justify-center ">
                        {hasCommentEditPermission && (
                          <div className="cursor-pointer   mt-4 text-red-500 " 
                          onClick={() => handleEditComment(comment._id, comment.content)}
                          >
                            Edit
                          </div>
                        )}
                        {hasRemovePermission && (
                          <div className="cursor-pointer  my-3  text-red-500 "  onClick={() => handleDeleteComment(comment._id)}>
                            Delete
                          </div>
                        )}
                        {hasCommentReportPermission && (
                          <div className="cursor-pointer  my-3  text-red-500 " onClick={() => toggleDropdown2(index)} >
                           Report
                          </div>
                        )}
                        <div className="w-full border border-neutral-600  " ></div>
                        <div className="cursor-pointer  my-2 text-md " onClick={onClose} > Cancel</div>
                      </div>
                    </div>
                    )}

         {showDropdown2 === index && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                      <div className="fixed inset-0 bg-black/50"></div>
                      <div className="z-50 bg-neutral-800 w-[400px] text-lg rounded-2xl h-[420px] overflow-y-auto scrollbar-hide shadow-lg text-white flex flex-col items-center justify-center">
                       
                        {isLoadingCategories ? (
                          <div className="loading-spinner my-[165px]">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          reportCategories.map((category) => (
                            <div
                              key={category._id}
                              className='cursor-pointer py-2 text-red-500'
                              onClick={() => handleReportComment(comment._id, category._id)}
                            >
                              {category.title}
                            </div>
                          ))
                        )}
                        <div className="w-full sticky  bottom-0 z-10 bg-neutral-700" onClick={onClose2}>
                          <div className="w-full border border-neutral-600"></div>
                          <div className="cursor-pointer text-md flex justify-center my-3">
                            Cancel
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
                  
                </div>
                <div className='ml-auto mt-5  flex '>
                <button className="text-white mr-3" onClick={() => handleLikeComment(comment._id)} >
                {isLikedByUser ? < ThumbUpAltIcon  /> : <ThumbUpOffAltIcon  />}
                </button>

                   <button className="text-white mr-2 mt-1.5" onClick={() => handleDislikeComment(comment._id)}  >
                  {isDislikedByUser ? < ThumbDownIcon /> : < ThumbDownOffAltIcon  />}
                     </button>

                </div>

              </div>

            
             

              {comment.replyCount > 0 && (
                <div className="text-gray-400 text-xs ml-24 mt-2 cursor-pointer" onClick={() => handleViewRepliesClick(comment._id)} >
                  --- {visibleReplies[comment._id] ? 'Hide replies' : `View replies (${comment.replyCount})`}
                </div>
              )}


{visibleReplies[comment._id] && (
        <div className="ml-12 mt-0">
          {loadingReplies[comment._id] ? (
            <div className="ml-24 mt-2"><CircularProgress size={20} /></div>
          ) : (
            comment.replies.map((reply) => (
              <div className="flex items-start mb-2" key={reply._id}>
                <div className="w-8 h-8 bg-white rounded-full overfow-hidden  mt-2 ml-2 flex-shrink-0 cursor-pointer" onClick={() => handleProfile(reply.user.userId)}>
                {reply.user && reply.user.profilePhoto && (
  <img
    src={`${PROFILEPHOTO_URL}/${reply.user.profilePhoto}`}
    alt="Profile"
    className="object-cover w-full h-full rounded-full"
  />
)}

                </div>

                <div className="flex flex-col justify-start mx-3 mt-2 " >
                  <span className="text-white " >
                
                   <span className="text-white cursor-pointer" onClick={() => handleProfile(reply.user.userId)}>
                  {reply.user.firstName.length + reply.user.lastName.length > 10 ? (
                      reply.user.firstName.length > 10 ? (
                        <span className="truncate text-white">
                          {`${ reply.user.firstName.slice(0, 8)}... `}
                        </span>
                      ) : (
                        <span className="truncate text-white">
                          {`${reply.user.firstName} ${reply.user.lastName.slice(0, 14 - reply.user.firstName.length - 1)}... `}
                        </span>
                      )
                    ) : (
                      <span className="truncate text-white">
                        {`${reply.user.firstName} ${reply.user.lastName} `}
                      </span>
                    )}
               </span>
                    <span className="ml-2 mx-2 text-smtext-white/80">{reply.content}</span>
      
    
                  </span>

                  <div className='text-gray-400 text-xs flex'>
                    {calculateTimeAgo(reply.createdAt)}
                    <div className='text-gray-400 tex-txs ml-4 flex'>
                      {reply.likeCount> 1 ? `${reply.likeCount} likes` : `${reply.likeCount} like`}
                      <div className='mx-3'>
                     {reply.dislikeCount > 1 ? `${reply.dislikeCount} dislikes` : `${reply.dislikeCount} dislike`}
                     </div>
                     </div>
                    {reply.isEdited && ( 
                    <div className="text-gray-400 text-xs ml-0 ">
                      Edited
                      </div>  
                      )}    
                    <div className='mt-0 flex items-center  ml-2 cursor-pointer'  onClick={() => toggleDropdown1(reply._id)} >
                   {(reply.hasCommentEditPermission || reply.hasCommentRemovePermission || reply.hasCommentReportPermission) && ( 
                    <MoreHorizIcon style={{ fontSize: 20 }} />
                    )}
                    </div>
                    {showDropdown1 === reply._id && (
                           <div className="fixed inset-0 z-50 flex items-center justify-center">
                           <div className="fixed inset-0 bg-black/50 " ></div>
                           <div className="z-50 bg-neutral-800 w-80  text-lg  rounded-2xl shadow-lg text-white flex flex-col items-center justify-center ">
                           {/* {reply.hasCommentEditPermission && (
                                <div className="cursor-pointer mt-4 text-red-500" onClick={() => handleEditReplyClick(reply._id, reply.content)}>
                                  Edit
                                </div>
                              )} */}
                            {reply.hasCommentRemovePermission && (
                              <div className="text-red-500 my-3 cursor-pointer" onClick={() => handleDeleteReply(reply._id , comment._id)}>
                               Delete
                              </div>
                             )}
                             {reply.hasCommentReportPermission && (
                              <div className="text-red-500 my-3 cursor-pointer" onClick={() => toggleDropdown3(index)}>
                               Report
                              </div>
                             )}

                             <div className="w-full border border-neutral-600  " ></div>
                             <div className="cursor-pointer  my-2 text-md " onClick={onClose1} > Cancel</div>
                           </div>
                         </div>
                    )}

{showDropdown3 === index && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                      <div className="fixed inset-0 bg-black/50"></div>
                      <div className="z-50 bg-neutral-800 w-[400px] text-lg rounded-2xl h-[420px] overflow-y-auto scrollbar-hide shadow-lg text-white flex flex-col items-center justify-center">
                       
                        {isLoadingCategories ? (
                          <div className="loading-spinner my-[165px]">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          reportCategories.map((category) => (
                            <div
                              key={category._id}
                              className='cursor-pointer py-2 text-red-500'
                              onClick={() => handleReportReply(reply._id, category._id)}
                            >
                              {category.title}
                            </div>
                          ))
                        )}
                        <div className="w-full sticky  bottom-0 z-10 bg-neutral-700" onClick={onClose3}>
                          <div className="w-full border border-neutral-600"></div>
                          <div className="cursor-pointer text-md flex justify-center my-3">
                            Cancel
                          </div>
                        </div>
                      </div>
                    </div>
                  )}



                  </div>

                </div> *
             <div className='ml-auto mt-5  flex '>
                <button className="text-white mr-3" onClick={() => handleLikeReply(reply._id, comment._id)} >
              {reply.isCommentLikedByUser ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
            </button>

            <button className="text-white mr-2"  onClick={() => handleDislikeReply(reply._id, comment._id)}>
              {reply.isCommentDislikedByUser ? <ThumbDownIcon /> : <ThumbDownOffAltIcon />}
            </button>

                </div> 
               </div>
            ))
          )}
        </div>
      )} 

            </div>
          );
        })}
        {loading && <div className='text-white'>Loading more...</div>}
        {!hasMore && <div className='text-gray-400 mt-4 mb-3 flex justify-center'>No more comments</div>}
        <div ref={containerRef}></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000} 
        hideProgressBar={true} 
        newestOnTop={true} 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
   <VideoSubmitComment
    addNewComment={addNewComment} 
    commentId={activeReply} 
    onSubmit={handleReplySubmit} 
    isReplying={!!activeReply} 
        onCancelReply={handleCancelReply}
        username={replyingToUser}
        editingCommentId={editingComment}
        editingContent={editingContent}
        originalCommentContent={editingComment ? comments.find(comment => comment._id === editingComment)?.content : ''}
        onEditSubmit={handleEditSubmit}
        isEditing={!!editingComment}
        onCancelEdit={handleCancelEdit}
        editingReplyId={editingReply}
        editingReplyContent={editingReplyContent}
        onReplyEditSubmit={handleReplyEditSubmit}
        onCancelEditReply={handleCancelEditReply}
     />

  </div>
               
    );
};

export default VideoComment;



