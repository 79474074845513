import React, { useState, useEffect , useRef} from 'react';
import { AudioIcon, CreateIcon, HomeIcon,  PostIcon, SearchIcon1, VidsIcon } from "./icon";
import { useNavigate, useLocation } from "react-router-dom";
import Smart from '../../../assets/images/SMARTGEARGREY1.png'
import smartIcon from '../../../assets/images/SMARTGREY.png'
import CircularProgress from '@mui/material/CircularProgress';
import { PROFILEPHOTO_URL } from '../../../Config/config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BookmarksIcon from '@mui/icons-material/Bookmarks';


const SmSidebar = ({user, loading, error}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const moreButtonRef = useRef(null);

    const handleMoreClick = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleprofile =  (userId) => {
    navigate(`/smartsocial/${userId}/`);
};

const toggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);
};


const handleBookmarkClick = () => {
  setIsDropdownOpen(false);
  navigate(`/smartsocial/${user.id}/saved/`);
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      moreButtonRef.current &&
      !moreButtonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [dropdownRef, moreButtonRef]);


// if (loading) return <div>Loading...</div>; 
//   if (error) return <div>Error: {error}</div>; 

  return (
    <div>
         
         <div className="fixed w-full z-20 right-0 h-16 bg-blue-900 overflow-hidden">
  <div className="flex h-full mx-auto font-medium items-center">
    
  <a href="/" className="flex items-center justify-between rtl:space-x-reverse">

    <img src={Smart} href='/' className="w-12 h-12  ml-0 sm:ml-1" />
    <img src={smartIcon} href='/' className="w-40 h-10" />
    </a>
    {/* <img src={smartIcon} className="w-40 h-10 hidden sm:block" /> */}

    <div className="flex items-center ml-auto mr-4">
      {/* <form className="w-[270px] sm:w-[350px] mx-2 ">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center pl-2 pointer-events-none">
            <SearchIcon1 />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-2 ps-8 text-sm text-gray-900 border border-black rounded-xl bg-black/30"
            placeholder="Search users...."
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2 top-2 bottom-2 bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2 py-0"
          >
            Search
          </button>
        </div>
      </form> */}
      <button
         onClick={toggleSidebar}
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 z-40 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400"
        aria-controls="mega-menu"
        aria-expanded={isSidebarOpen}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      {isSidebarOpen && (
        <div className="fixed inset-0 flex z-50">
          <div className="fixed inset-0 bg-black bg-opacity-50" onClick={toggleSidebar}></div>
          <div className="relative bg-white w-64 h-full shadow-xl p-4">
            <button onClick={toggleSidebar} className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="mt-8 space-y-4">
              <li>
                <a href="/" className="block py-2 px-3 text-gray-700 hover:bg-gray-100">
                  Home
                </a>
              </li>
              <li>
                <a href="/realestate" className="block py-2 px-3 text-gray-700 hover:bg-gray-100">
                  Real Estate
                </a>
              </li>
              <li>
                <a href="/smartsocial" className="block py-2 px-3 text-gray-700 hover:bg-gray-100">
                  Smart Social
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  </div>
</div>

     

<div className="fixed bottom-0 left-0 z-10 w-full h-16 bg-white/100  border-black ">
    <div className='flex justify-between h-full'>
     {/* <div className="grid h-full max-w-lg grid-cols-1 mx-auto font-medium"> */}
      <div className="  max-w-lg   font-medium">
        <a href='/' type="button" className="inline-flex flex-col items-center  border-gray-400 justify-center px-5 h-full hover:bg-gray-400 group  sm:ml-6 ">
          <div className='mt-2.5'> <HomeIcon /></div>
           <span className='text-blue-900 text-sm mt-1'>Home</span>
        </a>
          {/* <button type="button" className="inline-flex flex-col items-center justify-center px-5 mt-1.5 hover:bg-gray-400 group" onClick={() => handleNavigation("/smartsocial/vids")}>
            <VidsIcon />
            <span className='text-blue-900 text-sm mt-1'>Vids</span>
          </button> */}
           <a href='/smartsocial/vids' type="button" className="inline-flex flex-col items-center  border-gray-400 justify-center px-5 h-full hover:bg-gray-400 group  sm:ml-6 ">
          <div className='mt-2.5'>  <VidsIcon /></div>
           <span className='text-blue-900 text-sm mt-1'>Vids</span>
          </a>

          <a href='/smartsocial/post' type="button" className="inline-flex flex-col items-center  border-gray-400 justify-center px-5 h-full hover:bg-gray-400 group  sm:ml-6 ">
          <div className='mt-2.5'> <PostIcon /></div>
           <span className='text-blue-900 text-sm mt-1'>Post</span>
          </a>

        {/* <button type="button" className="inline-flex flex-col items-center justify-center px-5 mt-1.5 hover:bg-gray-400 group">
          <AudioIcon />
          <span className='text-blue-900 text-sm mt-1'>Audio</span>
        </button> */}
        {/* <button type="button" className="inline-flex flex-col items-center justify-center px-5 mt-1.5 hover:bg-gray-400 group">
          <CreateIcon />
          <span className='text-blue-900 text-sm mt-1'>Create</span>
        </button> */}
        </div>
        <div className="flex items-center pr-1.5">
        <button type="button" className="inline-flex flex-col  border-gray-400 items-center justify-center px-5  hover:bg-gray-400 group"   onClick={() => handleprofile(user && user.id)}>
           <div className="flex w-9 h-9 bg-white rounded-full overflow-hidden mt-1.5 items-center justify-center">
        {loading ? (
           <div role="status">
              <CircularProgress />
              </div>
           ) : error ? (
             <div className="error">Error loading image</div>
           ) : (
             user && <img src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
             )}    </div>
              <span className='text-blue-900 text-sm mt-0'>Profile</span>
        </button>
        <div className="cursor-pointer"
        onClick={handleMoreClick}
        ref={moreButtonRef}>
        <MoreVertIcon  style={{ fontSize: 42 , color :'#21379A'}}/>
      </div>
    </div>
    {isDropdownOpen && (
        <div ref={dropdownRef} className="absolute right-8 bottom-16  w-64 h-48 bg-white  border border-gray-700 rounded-lg shadow-lg z-10">
          <ul className="py-1">
  <div className="flex items-center px-4 py-2   mt-2 hover:bg-gray-200 cursor-pointer" onClick={handleBookmarkClick}>
    <BookmarksIcon style={{ fontSize: 30 , color :'#21379A'}} />
    <li className="ml-5 text-3xl text-company-color font-semibold">Saved</li> 
  </div>
  <div className=' border-b border-black/40 mt-2'>

  </div>
</ul>

        </div> 
      )}
    </div>
</div>
</div>
  );
};

export default SmSidebar;
