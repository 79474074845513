import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';
import { CircularProgress } from '@mui/material';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';


const Post = () => {
  const [posts, setPosts] = useState([]);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_URL}/posts/v3/public-posts`,
        headers: {},
      };

      try {
        const response = await axios.request(config);
        setPosts(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    if (posts.length > 0) {
      const interval = setInterval(() => {
        setCurrentPostIndex((prevIndex) => (prevIndex + 1) % posts.length);
      }, 6000);

      return () => clearInterval(interval); 
    }
  }, [posts]);

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  if (loading) {
    return <div className='flex items-center justify-center 2xl:w-[350px] 2xl:h-[500px] xl:w-[350px] xl:h-[500px] lg:w-[350px] lg:h-[500px] md:w-[300px] md:h-[450px]  mt-4'><CircularProgress /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (posts.length === 0) {
    return <p>No posts available</p>;
  }

  const currentPost = posts[currentPostIndex];

  return (
   
            <div className="flex flex-col 2xl:w-[350px] 2xl:h-[500px] xl:w-[350px] xl:h-[500px] lg:w-[350px] lg:h-[500px] md:w-[300px] md:h-[450px] mt-4   ">
             
                <div key={currentPost.id} className=" flex bg-gray-200  flex-col mb-1 rounded-xl shadow-2xl  ">
                <div className=" border-b border-black/20 ">

                  <div className="flex justify-between w-full items-center mt-2 ml-2 ">
                  
                  
                    <div className="flex items-center ">
                      <div className="bg-black rounded-full  w-10 h-10 mb-2  overflow-hidden "  >
                      {currentPost.user.profilePhoto && (
                                 <img src={`${PROFILEPHOTO_URL}/${currentPost.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />

        )}                      </div>
                      <div className="ml-4 mb-2">
                        <span className="block text-black text-sm " >
                        {currentPost.user.firstName} {currentPost.user.lastName}
                          </span>
                        <span className="block text-gray-500 text-xs">
                          {currentPost.location.length > 48 ? `${currentPost.location.slice(0, 48)}...` : currentPost.location}
                        </span>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="flex justify-center items-center mt-0 relative">
  {currentPost.contents.length > 1 && (
    <PhotoLibraryOutlinedIcon
      className="absolute top-2 right-2 text-white bg-black rounded-full p-1"
      style={{ fontSize: 24 }}
    />
  )}
  <div className="2xl:h-[350px] xl:h-[350px] lg:h-[350px] md:h-[280px] w-full flex justify-center items-center overflow-hidden">
  {imageLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/20">
                <CircularProgress />
              </div>
            )}
    <img
      src={`${PROFILEPHOTO_URL}/${currentPost.contents[0].url}`}
      alt="Post content"
      className="h-full object-cover"
      onLoad={handleImageLoad}
    />
  </div>
</div>

                 
                    <div  className='mt-0 border-t border-black/20'>
                    <div className="flex justify-between items-center  mx-2 ">
            <div className="flex items-center mt-0.5">
              {/* <div className="mx-1 " >
            <FavoriteOutlinedIcon  style={{ color: '#c62828',fontSize: 28 }} /> 
          </div> */}
              {/* <div className="mx-4 "  >
              <ChatBubbleOutlineIcon style={{ fontSize: 24 }} />                </div> */}
              {/* <ShareIcon /> */}
            </div>
            {/* <div className="" >
                   <BookmarkOutlinedIcon style={{ fontSize: 20 }} /> 
                </div>     */}
                      </div>
    
          <div className="flex text-sm mt-1.5 ml-3.5 text-gray-900">
            <div>
            {currentPost.likeCount > 1 ? `${currentPost.likeCount} Likes` : `${currentPost.likeCount} Like`}
            </div>
            <div className='ml-2'>
            {currentPost.commentCount > 1 ? `${currentPost.commentCount} Comments` : `${currentPost.commentCount} Comment`}
            </div>
          </div>
    
          <div className="flex ml-3.5 mt-0.5 mb-4">
            <span className="text-black text-sm mr-2 "  >
            <span className=" "  >
              {currentPost.user.firstName} {currentPost.user.lastName}
              </span>
            <span className="ml-2 text-xs text-gray-700">

         {currentPost.caption.length > 48 ? `${currentPost.caption.slice(0, 48)}...` : currentPost.caption}

</span>
            </span>
          </div>  
                    </div>

                </div>
            
            </div>
  
  );
};

export default Post;
