import React from "react";
import Trusteddocuments1 from '../../../assets/Homepageicon/doument.png';
import Comingsoon from '../../../assets/Homepageicon/coming-soon.png'


const Trusteddocuments = () => {

  return (
    <div className="mt-6 mb-10 flex items-center justify-center ">
      <div className="bg-gray-200/30 2xl:w-[1150px] xl:w-[1150px] lg:w-[950px] md:w-[800px]  mx-8 rounded-xl mb-0 flex">
        <div className="mt-4 ml-16 flex items-center">
        <img src={Comingsoon} alt="comingsoon" className="2xl:w-52 2xl:h-52 xl:w-52 xl:h-52 lg:w-48 lg:h-48 md:w-44 md:h-44 mb-4" />

        </div>
        <div>
        <h1 className="flex  mt-8 font-bold font-roboto 2xl:text-5xl xl:text-5xl lg:text-4xl md:text-3xl ml-32 text-center">
        Trusted Property Documents       
         </h1>
         <div className="flex flex-col items-center w-1/2 px-2  text-center mt-6 ml-48 ">
  <img src={Trusteddocuments1} alt="camera" className="w-12 h-12 mb-4" />
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-sm xl:text-sm lg:text-sm md:text-xs">
  Secure property documents store and manage all your property documents securely in one place.  </p>
</div>
         </div>
        

       



        </div>
      </div>
   
  );
};

export default Trusteddocuments;
