import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { PostIcon, VidsIcon, AudioIcon } from '../icon';
import PostsGrid from './savedpostgrid';
import VidsGrid from './savedvidsgrid';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import MovieIcon from '@mui/icons-material/Movie';
import MicIcon from '@mui/icons-material/Mic';


const Bookmark = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [activeTab, setActiveTab] = useState('post');
    const [value, setValue] = useState('1'); 
    const [activeModalTab, setActiveModalTab] = useState('1');

    useEffect(() => {
        const path = location.pathname;
    
        if (path.includes('/vids')) {
          setValue('1');
        } else if (path.includes('/post')) {
          setValue('2');
        } else if (path.includes('/audio')) {
          setValue('3');
        }
      }, [location.pathname]);
    
      const handleTabChange = (event, newValue) => {
        setValue(newValue);
        const path = newValue === '1' ? 'vids' : newValue === '2' ? 'post' : 'audio';
        navigate(path);
      };
    
 

    

    return (
       
       <div className="flex flex-col items-center post-container 2xl:ml-80 xl:ml-80 lg:ml-56 md:ml-16 mt-4 mb-14 overflow-hidden">
        <div className="relative 2xl:w-[1000px] xl:w-[920px] lg:w-[780px] md:w-[600px] mt-4">

        <Box sx={{ width: '100%', typography: 'body1' , marginTop:'2px'  }}>
            <TabContext value={value}>
              <Box sx={{
        borderBottom: 0.5,
        borderColor: 'white',
        color: 'white',
        padding: 0,
        margin: 0,
      }}
      >
                <TabList 
                  onChange={handleTabChange} 
                  aria-label="lab API tabs example"
                  sx={{
                    display: 'flex',
                    width: '100%',
                    padding: 0, 
                    margin: 0,
                  }}
                  TabIndicatorProps={{
                    style: { height: '3px', backgroundColor: 'white' ,  }
                  }}
                  variant="fullWidth"
                >
                  <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <MovieIcon style={{ fontSize: 30  }} active={value === '1'} />
                      </Box>
                    } 
                    label="Saved Vids" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3', 
                      fontSize: value === "1" ? '1.4rem' : '1.0rem', 
                      flexGrow: 1, 
                      textAlign: 'left',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="1" 
                  />
                  <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <InsertPhotoIcon style={{ fontSize: 30 }} active={value === '2'} />
                      </Box>
                    } 
                    label="Saved Post" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3',
                      fontSize: value === "2" ? '1.4rem' : '1.0rem',
                      flexGrow: 1, 
                      textAlign: 'center',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="2" 
                  />
                  {/* <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <MicIcon style={{ fontSize: 30 }} active={value === '3'} />
                      </Box>
                    } 
                    label="Saved Audio" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3',
                      fontSize: value === "3" ? '1.4rem' : '1.0rem', 
                      flexGrow: 1, 
                      textAlign: 'right',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="3" 
                  /> */}
                </TabList>
              </Box>
            </TabContext>
          </Box>
        
        <Routes>
            <Route path="vids"  element={<VidsGrid  />} />
            <Route path="post"  element={<PostsGrid  />} />
            <Route path="audio" />
            <Route path="/" element={<Navigate to="vids" replace />} />
        </Routes>
        </div>
        </div>
    );
}

export default Bookmark;
