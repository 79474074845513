import React, { useState, useEffect } from "react";
import Videosection from "./videosection";
import TextDisplay from "./TextDisplay";
import ScrollArrow from "./scrollbottom"; 
import Appnotification from "./Appnotification";
import Feature from "./feature";
import Realestate from "./realestate";
import Smartsocial from "./Smartsocial/smartsocial";
import Extrafeatures from "./Extrafeatures";
import Trusteddocuments from "./Trusteddocument";

const HomeSmall = () => {
  

  return (
    <div>
      <div  >
        <Videosection />
      </div>

      <div className="flex justify-center">
        <TextDisplay />
      </div>
      <div>
        <Appnotification />
      </div>
    <div>
      <ScrollArrow  />
     </div>
     <div >
     <Feature />
     </div>
     <div>
      <Smartsocial />
     </div>
     <div>
     <Realestate  />
     </div>
    
     <div>
      <Extrafeatures />
     </div>
     <div>
      <Trusteddocuments />
     </div>
    </div>
  );
};

export default HomeSmall;
