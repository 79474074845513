import React, { useState, useEffect, useRef } from 'react';
// import './style.css';
import {  LikeIcon,LikedIcon, CommentIcon, ShareIcon } from './vidsicon';
import { calculateTimeAgo } from '../Utils/timeutils';
import { formatDate } from '../Utils/dateutil';
import axios from 'axios';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import { API_URL } from '../../../../../Config/config';


const VidsBottomsection = ({ video }) => {
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');
    const [isUserLiked, setIsUserLiked] = useState(video.isUserLiked);
    const [likeCount, setLikeCount] = useState(video.likeCount);
   const [isUserBookmarked, setIsUserBookmarked] = useState(video.isUserBookmarked);
  const [bookmarkLoading, setBookmarkLoading] = useState(false); 

    useEffect(() => {
        if (video && video.createdAt) {
            const formattedTime = calculateTimeAgo(video.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [video]);
   

    const handleLikeClick = async () => {
        try {
          const token = localStorage.getItem('token');
          const vidsId = localStorage.getItem('vidsId');
    
          if (!token || !vidsId) {
            throw new Error('Token or Vids ID not found in local storage');
          }
    
          const url = `${API_URL}/vids/v2/like/${vidsId}`;
          const config = {
            method: 'post',
            url: url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
    
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount - 1 : prevCount + 1);
    
          const response = await axios.request(config);
    
          if (response.data.likeCount !== undefined) {
            setLikeCount(response.data.likeCount);
          }
    
        } catch (error) {
          console.error('Error toggling like status:', error);
        
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount + 1 : prevCount - 1);
        }
      };

 const handleBookmarkClick = async () => {
    if (bookmarkLoading) return;

    setBookmarkLoading(true);
    const token = localStorage.getItem('token');
    const vidsId = localStorage.getItem('vidsId');

  if (!token || !vidsId) {
        throw new Error('Token or  Vids ID not found in local storage');
      }

    try {
        const url = `${API_URL}/vids/v2/bookmark/${vidsId}`;
        const config = {
            method: 'post',
            url: url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        setIsUserBookmarked(!isUserBookmarked);

        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));

    } catch (error) {
        console.error('Error toggling bookmark status:', error);
        setIsUserBookmarked(!isUserBookmarked);
    } finally {
        setBookmarkLoading(false);
    }
};


    return (
        
               <>
                <div className="flex justify-between items-center  mx-2 ">
                    <div className="flex items-center mt-2">
                        <div className="mx-1 cursor-pointer" onClick={handleLikeClick}>
            {isUserLiked ? <FavoriteOutlinedIcon  style={{ color: 'red',fontSize: 40 }} /> : <FavoriteBorderIcon  style={{ color: 'white',fontSize: 40 }} />}
                            </div>
                        <div className="mx-3">
            <ChatBubbleOutlineIcon style={{ color: 'white' ,fontSize: 31 }} />
                          </div>
                        {/* <ShareIcon /> */}
                    </div>
                    <div className='cursor-pointer ' style={{ color: 'white' }} onClick={handleBookmarkClick}>
        {isUserBookmarked ? <BookmarkOutlinedIcon    style={{ fontSize: 30 }} /> : <BookmarkBorderOutlinedIcon   style={{ fontSize: 30 }} />}
                    </div>
                </div>
                <div className="flex text-sm mt-2 ml-4 text-white">
                    <div>{likeCount > 1 ? `${likeCount} likes` : `${likeCount} like`}</div>
                </div>
                <div className="flex text-sm mt-2 ml-4 text-gray-300 mb-1">
                    <div>{formatDate(video.createdAt)}</div>
                </div>
    </>
    );
};

export default VidsBottomsection;



