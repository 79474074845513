import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MdLgBookmark from './MdLgBookmark/index';
import SmBookmark from './SmBookmark/index';

const Bookmark = () => {

 
  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });

  return (
    <div >
      {is2XL ? <MdLgBookmark  /> :
        isXL ? <MdLgBookmark  /> :
          isLG ? <MdLgBookmark  /> :
            isMD ? <MdLgBookmark  /> :
              isSM ? <SmBookmark  /> :
                <SmBookmark />}
    </div>
  );
};

export default Bookmark;
