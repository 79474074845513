import React, { useState, useEffect } from 'react';
import { LikeIcon, ViewIcon, MultiIcon } from './smicon';
import Modal from './postmodal/postmodal';
import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import { toast, ToastContainer, Slide } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const SmPostsGrid = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const { userid } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState([]);
  const [hasShownToast, setHasShownToast] = useState(false); 


  const fetchPosts = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        // url: `${API_URL}/profile/v2/view?userId=${userid}&page_number=${page}`,
        url: `${API_URL}/profile/v2/${userid}/post?page_number=${page}`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      setLoadingMore(true);
      const response = await axios.request(config);
      const newPosts = response.data.data;

      setPosts(prevPosts => [...prevPosts, ...newPosts]);
      setLoadingPosts(prevLoadingPosts => [...prevLoadingPosts, ...new Array(newPosts.length).fill(true)]);
      setHasMore(newPosts.length > 0);

      // if (newPosts.length === 0 || newPosts.length < 10) {
      //   setHasMore(false);
      // }

      if (newPosts.length > 0) {
        setHasShownToast(false);
      }

    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [page]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 
  //         && hasMore && !loading && !loadingMore) {
  //       setPage(prevPage => prevPage + 1);
  //     }
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, loading, loadingMore]);
  

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2;

    
      if (isAtBottom) {
      
        if (hasMore && !loadingMore) {
          setPage(prevPage => prevPage + 1);
        } 
       
        else if (!hasMore && posts.length > 0 && !hasShownToast) {
          toast.info('No More Posts to load.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
           transition: Slide, 
           theme: "colored",
          });
          setHasShownToast(true); 
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loadingMore, posts.length, hasShownToast]); 


  const openModal = (post) => {
    setSelectedPost(post);
    localStorage.setItem('postId', post.id);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const handleImageLoad = (index) => {
    setLoadingPosts((prevLoadingPosts) => {
      const newLoadingPosts = [...prevLoadingPosts];
      newLoadingPosts[index] = false;
      return newLoadingPosts;
    });
  };

  return (
    <>
      <div className="relative mt-2">
        {loading && posts.length === 0 ? (
          <div className="mt-24 flex justify-center">
          <CircularProgress
        
           />
          </div>
        ) : (
          <>
                        {posts && posts.length > 0 ? (

            <div className="grid grid-cols-3 gap-1 sm:gap-4 mt-4">
            {posts.map((post, index) => (                  
            <div
                    key={post.id}
                    className="relative flex bg-black/20 cursor-pointer w-[120px] h-[120px] sm:w-48 sm:h-44 rounded-2xl"
                    onClick={() => openModal(post)}
                  >
                    {loadingPosts[index] && (
                      <div className="absolute inset-0 flex items-center justify-center z-10">
                        <CircularProgress />
                      </div>
                    )}
                    <img
                      src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`}
                      alt="Post"
                      className={`object-cover w-full h-full rounded-lg ${loadingPosts[index] ? 'hidden' : 'block'}`}
                      onLoad={() => handleImageLoad(index)}
                    />
                    {post.contents.length > 1 && (
                      <div className="text-white absolute top-2 right-2 ml-2 flex flex-col items-center">
                        <div className="relative flex items-center justify-center">
                          <MultiIcon />
                        </div>
                      </div>
                    )}
                    <div className="text-white absolute bottom-0 left-0 mb-1 ml-2 flex flex-col items-center">
                      <div className="relative flex items-center justify-center">
                        <ViewIcon />
                      </div>
                      <span className="text-white text-xs">{post.viewCount}</span>
                    </div>
                    <div className="text-white absolute bottom-0 right-0 mb-1 mr-2 flex flex-col items-center">
                      <div className="relative flex items-center justify-center">
                        <LikeIcon />
                      </div>
                      <span className="text-white text-xs">{post.likeCount}</span>
                    </div>
                  </div>
          ))}
          </div>
        ) : (
          !loading && (
            <div className="flex justify-center items-center h-96 w-full">
              <div className="text-gray-400 text-center">No posts yet uploaded.</div>
            </div>
          )
        )}
            {loadingMore && (
              <div className="mt-8 mb-12 flex justify-center">
                <FadeLoader
                color="#ffffff"
                width={4} />
              </div>
            )}
            {/* {!loadingMore && !hasMore && posts.length > 0 && (
              <div className="mt-4 text-gray-400 text-center">No more Posts to load.</div>
            )} */}
          </>
        )}
      </div>
      {selectedPost && (
        <Modal post={selectedPost} onClose={closeModal} />
      )}

<ToastContainer /> 

    </>
  );
};

export default SmPostsGrid;

// import React,{useState} from 'react';
// import { LikeIcon, ViewIcon,MultiIcon } from './smicon';
// import Modal from './postmodal/postmodal';
// import { PROFILEPHOTO_URL } from '../../../../Config/config';
// import CircularProgress from '@mui/material/CircularProgress';


// const SmPostsGrid = ({ posts }) => {
//   const [selectedPost, setSelectedPost] = useState(null);
//   const [loadingPosts, setLoadingPosts] = useState(posts.map(() => true));

//   const openModal = (post) => {
//     setSelectedPost(post);
//     localStorage.setItem('postId', post.id);
//   };

//   const closeModal = () => {
//     setSelectedPost(null);
//   };

//   const handleImageLoad = (index) => {
//     setLoadingPosts((prevLoadingPosts) => {
//       const newLoadingPosts = [...prevLoadingPosts];
//       newLoadingPosts[index] = false;
//       return newLoadingPosts;
//     });
//   };

//   return (
//     <>
//     <div className="grid grid-cols-3 gap-1 sm:gap-4 mt-4 ">
//          {posts && posts.length > 0 ? (
//         posts.map((post, index) => (
//      <div     
//      key={post.id} 
//      className=' relative flex bg-black/20 cursor-pointer w-[120px] h-[120px] sm:w-48 sm:h-44  rounded-2xl  '
//      onClick={() => openModal(post)}
//      >
//        {loadingPosts[index] && (
//                 <div className="absolute inset-0 flex items-center justify-center z-10">
//                   <CircularProgress />
//                 </div>
//               )}

//               <img src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`} alt="Post" className={`object-cover w-full h-full rounded-lg ${loadingPosts[index] ? 'hidden' : 'block'}`}
//                      onLoad={() => handleImageLoad(index)}
//                      />

//      {post.contents.length > 1 && (
//                 <div className="text-white absolute top-2 right-2 ml-2 flex flex-col items-center">
//                   <div className="relative flex items-center justify-center">
//                     <MultiIcon />
//                   </div>
//                 </div>
//               )}

//      <div className="text-white absolute bottom-0 left-0 mb-1 ml-2 flex flex-col items-center">
//           <div className="relative flex items-center justify-center">
//            <ViewIcon />
//            </div>
//          <span className="text-white text-xs">{post.viewCount}</span>
//      </div>
//      <div className="text-white absolute 2 bottom-0 right-0 mb-1 mr-2 flex flex-col items-center"> 
//       <div className="relative flex items-center justify-center">
//        <LikeIcon />
//        </div>
//        <span className="text-white text-xs ">{post.likeCount}</span>
//         </div> 
//      </div>
//       ))
//       ) : (
//         <div className="text-white">No posts available.</div>
//       )}
//     </div>
//      {selectedPost && (
//       <Modal post={selectedPost} onClose={closeModal} />
//   )}
// </>
//   );
// };

// export default SmPostsGrid;
