import React, { useState, useEffect } from "react";
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Downarrow from '../../../assets/Homepageicon/downarrow.png';
import Uparrow from '../../../assets/Homepageicon/uparrow.png';
import Uparrow1 from '../../../assets/Homepageicon/up-arrows(1).png';


const ScrollArrow = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  
  const checkScrollPosition = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    setIsAtBottom(scrollTop + windowHeight >= documentHeight - 10);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="fixed bottom-4 right-10 transform -translate-x-1/2 cursor-pointer animate-bounce" onClick={isAtBottom ? scrollToTop : scrollToBottom}>
      {isAtBottom ? (
        <img src={Uparrow1} alt="Scroll to top" style={{ width: 60, height: 60 }} />
      ) : (
        <img src={Downarrow} alt="Scroll to bottom" style={{ width: 60, height: 60 }} />
      )}
    </div>
  );
};

export default ScrollArrow;
