import React from "react";
import realestate from '../../../assets/Homepageicon/real-estate.png';
import camera from '../../../assets/Homepageicon/camera.png';
import gear from '../../../assets/Homepageicon/gear.png';


const Feature = () => {
  
  return (
    <div className="mt-6 mb-10 flex items-center justify-center ">
      <div className="bg-gray-200/30 2xl:w-[1150px] xl:w-[1150px] lg:w-[950px] md:w-[800px]  mx-8 rounded-xl mb-0">
        <h1 className="flex justify-center mt-8 font-bold font-roboto 2xl:text-5xl xl:text-5xl  lg:text-4xl md:text-3xl text-center">
          Connect, Discover, and Share — Experience Smart Like Never Before!
        </h1>

        <div className="flex flex-row items-center mt-24 mb-8">
        <div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2 text-center mt-0">
  <img src={realestate} alt="real estate" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4 " />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Real Estate Made Easy</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
    Find your ideal home or investment
    with our advanced property search tools.
  </p>
</div>

<div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2  text-center mt-0 ">
  <img src={camera} alt="camera" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4" />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Smart Social</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
    Share posts, videos, audio, and connect with others in our vibrant smart social community.
  </p>
</div>

<div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2  text-center mt-0 ">
  <img src={gear} alt="camera" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4" />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Smart Essentials</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Access useful features like weather updates, daily horoscopes, and create digital business cards to stay informed and connected.
  </p>
</div>

        </div>
      </div>
    </div>
  );
};

export default Feature;
