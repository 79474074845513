import React from "react";
import Trusteddocuments1 from '../../../assets/Homepageicon/doument.png';
import Comingsoon from '../../../assets/Homepageicon/coming-soon.png'


const Trusteddocuments = () => {

  return (
    <div className="mt-6 mb-10 flex items-center justify-center ">
      <div className="bg-gray-200/30 sm:w-[540px]  w-[360px] rounded-xl mb-0 sm:flex  ">
        <div className="mt-4 sm:ml-8 ml-0 flex sm:items-center justify-center">
        <img src={Comingsoon} alt="comingsoon" className="w-36 h-32 " />

        </div>
        <div>
        <h1 className="flex  mt-8 font-bold font-roboto  text-xl justify-center sm:ml-20 ml-0 text-center">
        Trusted Property Documents       
         </h1>
         <div className="flex flex-col items-center px-2  text-center mt-6  mb-4 sm:ml-4 ml-0 ">
  <img src={Trusteddocuments1} alt="camera" className="sm:w-10 sm:h-10 w-8 h-8 mb-4" />
  <p className="mt-2 font-roboto text-gray-600 font-normal text-sm mx-2">
  Secure property documents store and manage all your property documents securely in one place.  </p>
</div>
         </div>
        

       



        </div>
      </div>
   
  );
};

export default Trusteddocuments;
