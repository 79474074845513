import React, { useState, useEffect } from "react";

const TextDisplay = () => {
  
  const phrases = [
    "Discover properties",
    "Post updates",
    "Share Vids",
    "Find your dream home",
    "Connect socially",
    "Capture moments",
    "Explore real estate",
    "Share moments",
    "Create memories",
    "Browse listings",
    "Express yourself",
    "Make videos",
  ];

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("opacity-100");

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass("opacity-0");
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) =>
          prevIndex === phrases.length - 1 ? 0 : prevIndex + 1
        );
        setFadeClass("opacity-100");
      }, 500);
    }, 2500);

    return () => clearInterval(interval);
  }, [phrases.length]);

  return (
    <div className="mb-12 mt-4 2xl:w-[1150px] xl:w-[1150px] lg:w-[950px] md:w-[800px]">
      <div className="text-center p-5">
        <h1 className={`transition-opacity duration-500 font-bold text-6xl text-company-color font-robot ${fadeClass}`}>
          {phrases[currentPhraseIndex]}
        </h1>
        <p className="font-semibold text-5xl  font-robot mt-6">SMART lets you do it all</p>
      </div>
      <div className="mt-12">
        <p className="2xl:text-3xl xl:text-4xl lg:text-3xl md:text-2xl text-center text-gray-600 font-normal font-roboto">
          From searching real estate across the USA to creating posts and videos 
           with smart social, our platform gives you everything you need for 
          property and social media in one place.
        </p>
      </div>
    </div>
  );
};

export default TextDisplay;


    // <div className="relative mb-10 mt-4 text-center p-5">
    //   {/* Full gradient background behind all text */}
    //   <div className="absolute inset-0 bg-gradient-to-r from-pink-200 via-purple-200 to-yellow-100 rounded-md blur-lg"></div>
      
    //   {/* Text content with relative positioning to stay above the background */}
    //   <div className="relative z-10">
    //     <h1 className={`transition-opacity duration-500 font-bold text-6xl text-company-color font-robot ${fadeClass}`}>
    //       {phrases[currentPhraseIndex]}
    //     </h1>
    //     <p className="font-bold text-5xl font-robot mt-4">
    //       SMART lets you do it all
    //     </p>
    //     <div className="mt-8">
    //       <p className="text-4xl text-center text-gray-400 font-semibold font-roboto">
    //         From searching real estate across the USA to creating posts and Vids
    //         <br />
    //         with Smart Social, our platform gives you everything you need for
    //         <br />
    //         property and social media in one place.
    //       </p>
    //     </div>
    //   </div>
    // </div>
  