import React from "react";
import realestate from '../../../assets/Homepageicon/real-estate.png';
import camera from '../../../assets/Homepageicon/camera.png';
import gear from '../../../assets/Homepageicon/gear.png';


const Feature = () => {
  
  return (
    
    <div className="mt-6 mb-10 flex items-center justify-center ">
      <div className="bg-gray-200/30 sm:w-[540px]  w-[360px]  mx-8 rounded-xl mb-0">
        <h1 className="flex justify-center items-center mt-8 font-bold font-roboto sm:text-xl text-lg mx-2 text-center">
          Connect, Discover, and Share — Experience Smart Like Never Before!
        </h1>

        <div className=" mt-16 mb-8">
        <div className=" flex flex-col items-center  px-1 text-center mt-0">
  <img src={realestate} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Real Estate Made Easy</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
    Find your ideal home or investment
    with our advanced property search tools.
  </p>
</div>

<div className=" flex flex-col items-center  px-1 text-center mt-4">
  <img src={camera} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Smart Social</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Share posts, videos, audio, and connect with others in our vibrant smart social community.

  </p>
</div>

<div className=" flex flex-col items-center  px-1 text-center mt-4">
  <img src={gear} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Smart Essentials</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Access useful features like weather updates, daily horoscopes, and create digital business cards to stay informed and connected.

  </p>
</div>



        </div>
      </div>
    </div>
  );
};

export default Feature;
