
 import React from "react";

 export const HomeIcon = () => (
      <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 35.5556H12.5V22.2222H27.5V35.5556H35V15.5556L20 5.55556L5 15.5556V35.5556ZM0 40V13.3333L20 0L40 13.3333V40H22.5V26.6667H17.5V40H0Z" fill="#1e40af" />
</svg>
 )

 export const SearchIcon = () => (
    <svg width="25" height="25" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.2805 40L23.2805 26C22.1694 26.8889 20.8916 27.5926 19.4472 28.1111C18.0027 28.6296 16.4657 28.8889 14.836 28.8889C10.799 28.8889 7.38271 27.4904 4.58716 24.6933C1.7916 21.8963 0.393084 18.48 0.391603 14.4444C0.390121 10.4089 1.78864 6.99259 4.58716 4.19556C7.38568 1.39852 10.802 0 14.836 0C18.8701 0 22.2872 1.39852 25.0872 4.19556C27.8872 6.99259 29.2849 10.4089 29.2805 14.4444C29.2805 16.0741 29.0212 17.6111 28.5027 19.0556C27.9842 20.5 27.2805 21.7778 26.3916 22.8889L40.3916 36.8889L37.2805 40ZM14.836 24.4444C17.6138 24.4444 19.9753 23.4726 21.9205 21.5289C23.8657 19.5852 24.8375 17.2237 24.8361 14.4444C24.8346 11.6652 23.8627 9.30445 21.9205 7.36222C19.9783 5.42 17.6168 4.44741 14.836 4.44444C12.0553 4.44148 9.69457 5.41407 7.75383 7.36222C5.81308 9.31037 4.84049 11.6711 4.83605 14.4444C4.8316 17.2178 5.8042 19.5793 7.75383 21.5289C9.70345 23.4785 12.0642 24.4504 14.836 24.4444Z" fill="#1e40af"/>
    </svg>
)

export const SearchIcon1 = () => (
    <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
)

export const VidsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M39.8036 8.518H31.1477V0.424C33.1562 0.933282 35.0032 1.94252 36.5166 3.35774C38.0301 4.77296 39.1609 6.54814 39.8036 8.518ZM9.69209 0.424V8.518H0.942229C1.59176 6.53596 2.73622 4.7524 4.26733 3.33606C5.79844 1.91972 7.66557 0.917427 9.69209 0.424ZM27.6178 0H13.222V8.518H27.6178V0ZM40.3916 12.048H0.400237C0.388723 12.149 0.388723 12.251 0.400237 12.352V27.646C0.40021 30.9138 1.6949 34.0486 4.00089 36.364C6.30687 38.6794 9.43628 39.9868 12.704 40H27.9938C31.2684 39.9947 34.4074 38.6914 36.7227 36.3756C39.038 34.0599 40.3408 30.9207 40.3456 27.646V12.352C40.3725 12.2528 40.388 12.1508 40.3916 12.048ZM27.7358 26.328C27.5515 26.6766 27.2952 26.982 26.9838 27.224L19.9239 32.164C19.5498 32.4257 19.1114 32.5806 18.6559 32.612H18.42C18.0054 32.6182 17.5957 32.5228 17.2265 32.3342C16.8573 32.1456 16.5399 31.8695 16.302 31.53C16.042 31.14 15.902 30.68 15.902 30.21V20.99C15.9 20.53 16.03 20.08 16.28 19.694C16.54 19.34 16.878 19.05 17.268 18.848C17.668 18.668 18.1 18.578 18.5379 18.588C18.9599 18.608 19.3719 18.728 19.7379 18.942L26.7918 23.2C27.1374 23.3989 27.4268 23.6824 27.6329 24.0239C27.8389 24.3653 27.9549 24.7535 27.9698 25.152C28.0018 25.56 27.9198 25.966 27.7338 26.33" fill="#1e40af"/>
       </svg>
)

export const PostIcon = () => (
    <svg width="24" height="24" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M3.24874 0C2.49098 0 1.76426 0.30102 1.22844 0.836838C0.692622 1.37266 0.391602 2.09938 0.391602 2.85714V37.1429C0.391602 37.9006 0.692622 38.6273 1.22844 39.1632C1.76426 39.699 2.49098 40 3.24874 40H37.5345C38.2922 40 39.0189 39.699 39.5548 39.1632C40.0906 38.6273 40.3916 37.9006 40.3916 37.1429V2.85714C40.3916 2.09938 40.0906 1.37266 39.5548 0.836838C39.0189 0.30102 38.2922 0 37.5345 0H3.24874ZM13.2487 17.1429C14.3854 17.1429 15.4755 16.6913 16.2792 15.8876C17.0829 15.0839 17.5345 13.9938 17.5345 12.8571C17.5345 11.7205 17.0829 10.6304 16.2792 9.82668C15.4755 9.02296 14.3854 8.57143 13.2487 8.57143C12.1121 8.57143 11.022 9.02296 10.2183 9.82668C9.41456 10.6304 8.96303 11.7205 8.96303 12.8571C8.96303 13.9938 9.41456 15.0839 10.2183 15.8876C11.022 16.6913 12.1121 17.1429 13.2487 17.1429ZM7.4716 30.6714L12.0373 23.3657C12.1657 23.1602 12.3443 22.9908 12.5562 22.8734C12.7681 22.7559 13.0065 22.6943 13.2487 22.6943C13.491 22.6943 13.7294 22.7559 13.9413 22.8734C14.1532 22.9908 14.3318 23.1602 14.4602 23.3657L18.1887 29.3314L24.1602 19.2257C24.2865 19.0119 24.4664 18.8347 24.6821 18.7116C24.8978 18.5885 25.1418 18.5237 25.3902 18.5237C25.6385 18.5237 25.8826 18.5885 26.0983 18.7116C26.314 18.8347 26.4939 19.0119 26.6202 19.2257L33.403 30.7029C33.5309 30.9197 33.5992 31.1664 33.6011 31.4182C33.6029 31.6699 33.5382 31.9176 33.4135 32.1363C33.2888 32.3549 33.1085 32.5368 32.8909 32.6634C32.6733 32.79 32.4262 32.8568 32.1745 32.8571H18.6087C18.4744 32.8566 18.3407 32.8373 18.2116 32.8C18.0816 32.8376 17.9469 32.8568 17.8116 32.8571H8.68589C8.43088 32.8571 8.18052 32.7889 7.9608 32.6595C7.74108 32.53 7.56001 32.3441 7.4364 32.1211C7.31278 31.8981 7.25111 31.646 7.2578 31.3911C7.2645 31.1362 7.33645 30.8877 7.4716 30.6714Z" fill="#1e40af"/>
</svg>
)

export const AudioIcon = () => (
    <svg width="24" height="25" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.3916 25C26.0825 25 30.6946 21.6429 30.6946 17.5V7.5C30.6946 3.35714 26.0825 0 20.3916 0C14.7007 0 10.0886 3.35714 10.0886 7.5V17.5C10.0886 21.6429 14.7007 25 20.3916 25ZM40.3916 17.4107C40.3916 17.2143 40.1734 17.0536 39.9068 17.0536H36.2704C36.0037 17.0536 35.7855 17.2143 35.7855 17.4107C35.7855 23.6741 28.8946 28.75 20.3916 28.75C11.8886 28.75 4.99766 23.6741 4.99766 17.4107C4.99766 17.2143 4.77948 17.0536 4.51281 17.0536H0.87645C0.609783 17.0536 0.391602 17.2143 0.391602 17.4107C0.391602 24.942 8.06433 31.1562 17.9674 32.0357V36.6071H9.1613C8.331 36.6071 7.66433 37.2455 7.66433 38.0357V39.6429C7.66433 39.8393 7.83403 40 8.04009 40H32.7431C32.9492 40 33.1189 39.8393 33.1189 39.6429V38.0357C33.1189 37.2455 32.4522 36.6071 31.6219 36.6071H22.5734V32.058C32.5916 31.2545 40.3916 25.0045 40.3916 17.4107Z" fill="#1e40af"/>
</svg>
)

export const CreateIcon = () => (
    <svg width="25" height="25" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M42 24.8571H29.1429V12H24.8571V24.8571H12V29.1429H24.8571V42H29.1429V29.1429H42V24.8571Z" fill="#1e40af"/>
        <path d="M2.3916 27C2.3916 15.2158 2.3916 9.32105 6.05213 5.66053C9.71265 2 15.6048 2 27.3916 2C39.1758 2 45.0705 2 48.7311 5.66053C52.3916 9.32105 52.3916 15.2132 52.3916 27C52.3916 38.7842 52.3916 44.6789 48.7311 48.3395C45.0705 52 39.1784 52 27.3916 52C15.6074 52 9.71265 52 6.05213 48.3395C2.3916 44.6789 2.3916 38.7868 2.3916 27Z" stroke="#1e40af" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
     </svg>
)

export const MoreIcon = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="2em"
  height="2em"
  viewBox="0 0 20 20"
  className="fill-current text-company-color"
>
  <path d="M2 4.75A.75.75 0 0 1 2.75 4h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 4.75m0 5A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75M2.75 14a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5z" />
</svg>
)

export const Loadericon = () => (
    <svg
      aria-hidden="true"
      className="inline w-10 h-10  animate-spin  fill-blue-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
  









// import React, { useState } from "react";
// import { HomeIcon, SearchIcon, VidsIcon, PostIcon } from "./icon";

// const Sidebar = () => {
//     const [hover, setHover] = useState(false);

//     return (
//         <div className="flex flex-col justify-between bg-white w-16 sm:w-24 md:w-36 lg:w-80 xl:w-80 mt-24" style={{ height: 'calc(100vh - 6rem)' }}>
//             <ul className="text-black text-2xl font-semibold pt-6 cursor-pointer flex-grow">
//                 <li className="flex hover:bg-gray-400 px-4 py-4 hover:text-white hover:rounded-xl hover:mx-1" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
//                     <HomeIcon color="black" hover={hover} />
//                     <div className="px-6">Home</div>
//                 </li>
//                 <li className="flex hover:bg-gray-400 px-4 py-4 hover:text-white hover:rounded-xl hover:mx-1" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
//                     <SearchIcon color={hover ? "white" : "black"} />
//                     <div className="px-6">Search</div>
//                 </li>
//                 <li className="flex hover:bg-gray-400 px-4 py-4 hover:text-white hover:rounded-xl hover:mx-1" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
//                     <VidsIcon color={hover ? "white" : "black"} />
//                     <div className="px-6">Vids</div>
//                 </li>
//                 {/* <li className="flex hover:bg-gray-400 px-4 py-4 hover:text-white hover:rounded-xl hover:mx-1" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
//                     <PostIcon color={hover ? "white" : "black"} />
//                     <div className="px-6">Post</div>
//                 </li> */}
//             </ul>
//             <div className="text-black text-2xl font-semibold px-6 pb-6">Profile</div>
//         </div>
//     );
// };

// export default Sidebar;

