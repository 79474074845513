import React from 'react';
import HomeLarge from './Largescreen/index';
import HomeSmall from './Smallscreen/index';

import { useMediaQuery } from 'react-responsive';

const Home = () => {
  
  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });

 
  return (
    <div >
      {is2XL ? <HomeLarge /> :
        isXL ? <HomeLarge /> :
          isLG ? <HomeLarge /> :
            isMD ? <HomeLarge /> :
              isSM ? <HomeSmall /> :
              <HomeSmall />}
    </div>
  );
};

export default Home;