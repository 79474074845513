// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { CircularProgress } from '@mui/material'; 
// import { LikeIcon, ViewIcon, MultiIcon } from './icon';
// import Modal from './Postmodal/PostModal';
// import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';

// const PostsGrid = () => {
//   const { userid } = useParams();
//   const [posts, setPosts] = useState([]);
//   const [selectedPost, setSelectedPost] = useState(null);
//   const [loading, setLoading] = useState(true); 
//   const [loadingMore, setLoadingMore] = useState(false); 
//   const [page, setPage] = useState(0); 
//   const [hasMore, setHasMore] = useState(true); 
//   const [loadingImages, setLoadingImages] = useState([]);

//   const fetchPosts = async (pageNumber) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       console.error('Token not found in localStorage');
//       return;
//     }

//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${API_URL}/profile/v2/${userid}/post?page_number=${pageNumber}`,
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await axios.request(config);
//       const newPosts = response.data.data;
      
    
//       const uniqueNewPosts = newPosts.filter(newPost => 
//         !posts.some(post => post.id === newPost.id)
//       );

//       setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
//       setLoadingImages(prevLoadingImages => [
//         ...prevLoadingImages, 
//         ...new Array(uniqueNewPosts.length).fill(true)
//       ]);
//       setHasMore(newPosts.length > 0 && newPosts.length === 10); 

//     } catch (error) {
//       console.error('Error fetching posts:', error);
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     fetchPosts(0); 
//   }, [userid]);

//   useEffect(() => {
//     if (page > 0) {
//       setLoadingMore(true);
//       fetchPosts(page);
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if ((window.innerHeight + document.documentElement.scrollTop + 100) >= document.documentElement.scrollHeight 
//           && hasMore && !loading && !loadingMore) {
//         setPage(prevPage => prevPage + 1);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [hasMore, loading, loadingMore]);

//   const openModal = (post) => {
//     setSelectedPost(post);
//     localStorage.setItem('postId', post.id);
//   };

//   const closeModal = () => {
//     setSelectedPost(null);
//   };

//   const handleImageLoad = (index) => {
//     setLoadingImages(prevLoadingImages => {
//       const newLoadingImages = [...prevLoadingImages];
//       newLoadingImages[index] = false;
//       return newLoadingImages;
//     });
//   };

//   return (
//     <>
//       <div className="relative">
//         {loading && posts.length === 0 ? (
//           <div className="mt-24 flex justify-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <>
//             <div className="grid grid-cols-3 gap-4 mt-4">
//               {posts.length > 0 ? (
//                 posts.map((post, index) => (
//                   <div
//                     key={post.id}
//                     className="relative flex bg-black/20 2xl:w-64 2xl:h-64 xl:w-64 xl:h-64 lg:w-56 lg:h-60 md:w-48 md:h-48 rounded-2xl cursor-pointer hover:scale-105 ease-in-out duration-500"
//                     onClick={() => openModal(post)}
//                   >
//                     {loadingImages[index] && (
//                       <div className="absolute inset-0 flex items-center justify-center">
//                         <CircularProgress />
//                       </div>
//                     )}
//                     <img 
//                       src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`} 
//                       alt="Post" 
//                       className={`object-cover w-full h-full rounded-lg ${loadingImages[index] ? 'hidden' : 'block'}`}
//                       onLoad={() => handleImageLoad(index)}
//                     />
                    
//                     {post.contents.length > 1 && (
//                       <div className="text-white absolute top-2 right-2 ml-2 flex flex-col items-center">
//                         <div className="relative flex items-center justify-center">
//                           <MultiIcon />
//                         </div>
//                       </div>
//                     )}

//                     <div className="text-white absolute bottom-0 left-0 mb-2 ml-2 flex flex-col items-center">
//                       <div className="relative flex items-center justify-center">
//                         <ViewIcon />
//                       </div>
//                       <span className="text-white text-xs">{post.viewCount}</span>
//                     </div>

//                     <div className="text-white absolute bottom-0 right-0 mb-2 mr-2 flex flex-col items-center">
//                       <div className="relative flex items-center justify-center">
//                         <LikeIcon />
//                       </div>
//                       <span className="text-white text-xs">{post.likeCount}</span>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 !loading && (
//                   <div className="text-gray-400 text-center w-full">No posts yet uploaded.</div>
//                 )
//               )}
//             </div>
//             {loadingMore && (
//               <div className="mt-8 flex justify-center">
//                 <CircularProgress />
//               </div>
//             )}
//             {!loadingMore && !hasMore && posts.length > 0 && (
//               <div className="mt-4 text-gray-400 text-center">No more posts.</div>
//             )}
//           </>
//         )}
//       </div>
//       {selectedPost && (
//         <Modal post={selectedPost} onClose={closeModal} />
//       )}
//     </>
//   );
// };

// export default PostsGrid;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material'; 
import { LikeIcon, ViewIcon, MultiIcon } from './icon';
import Modal from './Postmodal/PostModal';
import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';
import { toast, ToastContainer , Slide} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';

const PostsGrid = () => {
  const { userid } = useParams();
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [loadingMore, setLoadingMore] = useState(false); 
  const [page, setPage] = useState(0); 
  const [hasMore, setHasMore] = useState(true); 
  const [loadingPosts, setLoadingPosts] = useState([]);
  const [hasShownToast, setHasShownToast] = useState(false); 

  const fetchPosts = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        // url: `${API_URL}/profile/v2/view?userId=${userid}&page_number=${page}`,
        url: `${API_URL}/profile/v2/${userid}/post?page_number=${page}`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      setLoadingMore(true); 
      const response = await axios.request(config);
      const newPosts = response.data.data;

      
      setPosts(prevPosts => [...prevPosts, ...newPosts]);
      setLoadingPosts(prevLoadingPosts => [...prevLoadingPosts, ...new Array(newPosts.length).fill(true)]);

      setHasMore(newPosts.length > 0);

      
      // if (newPosts.length === 0 || newPosts.length < 10) { 
      //   setHasMore(false);
      // }

      if (newPosts.length > 0) {
        setHasShownToast(false);
      }
      
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false); 
      setLoadingMore(false); 
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [page]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 
  //         && hasMore && !loading && !loadingMore) {
  //       setPage(prevPage => prevPage + 1);
  //     }
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, loading, loadingMore]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 && !loadingMore) {
  //       if (hasMore) {
  //         setPage(prevPage => prevPage + 1);
  //       } else if (!hasShownToast && posts.length > 0) {
         
  //         toast.info('No more Posts to load.', {
  //           position: 'bottom-center',
  //           autoClose: 3000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //         });

  //         setHasShownToast(true); 
  //       }
  //     }
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, loading, loadingMore, posts.length, hasShownToast]);

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2;

    
      if (isAtBottom) {
      
        if (hasMore && !loadingMore) {
          setPage(prevPage => prevPage + 1);
        } 
       
        else if (!hasMore && posts.length > 0 && !hasShownToast) {
          toast.info('No More Posts to load.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            transition: Slide, 
            theme: "colored",
          });
          setHasShownToast(true); 
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loadingMore, posts.length, hasShownToast]); 



  const openModal = (post) => {
    setSelectedPost(post);
    localStorage.setItem('postId', post.id);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const handleImageLoad = (index) => {
    setLoadingPosts((prevLoadingPosts) => {
      const newLoadingPosts = [...prevLoadingPosts];
      newLoadingPosts[index] = false;
      return newLoadingPosts;
    });
  };



  const incrementCommentCount = (postId) => {
    setPosts(prevPosts =>
      prevPosts.map(post =>
        post.id === postId ? { ...post, commentCount: post.commentCount + 1 } : post
      )
    );
  };
  

  const decrementCommentCount = (postId) => {
    setPosts(prevPosts =>
      prevPosts.map(post =>
        post.id === postId && post.commentCount > 0 ? { ...post, commentCount: post.commentCount - 1 } : post
      )
    );
  };
  
  return (
    <>
      <div className="relative mt-8">
        {loading && posts.length === 0 ? (
          <div className="mt-24 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            {posts.length > 0 ? (
              <div className="grid grid-cols-3 gap-4 mt-4">
                {posts.map((post, index) => (
                  <div
                    key={post.id}
                    className="relative flex bg-black/20 2xl:w-64 2xl:h-64 xl:w-64 xl:h-64 lg:w-56 lg:h-60 md:w-48 md:h-48 rounded-2xl cursor-pointer hover:scale-105 ease-in-out duration-500"
                    onClick={() => openModal(post)}
                  >
                    {loadingPosts[index] && (
                      <div className="absolute inset-0 flex items-center justify-center z-10">
                        <CircularProgress />
                      </div>
                    )}
                    <img 
                      src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`} 
                      alt="Post" 
                      className={`object-cover w-full h-full rounded-lg ${loadingPosts[index] ? 'hidden' : 'block'}`}
                      onLoad={() => handleImageLoad(index)}
                    />
                    {post.contents.length > 1 && (
                      <div className="text-white absolute top-2 right-2 ml-2 flex flex-col items-center">
                        <div className="relative flex items-center justify-center">
                        <PhotoLibraryOutlinedIcon style={{ color: 'white' ,fontSize: 25 }} />
                        </div>
                      </div>
                    )}
                    <div className="text-white absolute bottom-0 left-0 mb-2 ml-2 flex flex-col items-center">
                      <div className="relative flex items-center justify-center">
                      <VisibilityOutlinedIcon style={{ color: 'white' ,fontSize: 31 }} />
                      </div>
                      <span className="text-white text-md font-bold">{post.viewCount}</span>
                    </div>
                    <div className="text-white absolute bottom-0 right-0 mb-2 mr-2 flex flex-col items-center">
                      <div className="relative flex items-center justify-center">
                      <FavoriteBorderOutlinedIcon style={{ color: 'white' ,fontSize: 31 }} />
                      </div>
                      <span className="text-white text-md font-bold">{post.likeCount}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !loading && (
                <div className="flex justify-center items-center h-96 w-full">
                  <div className="text-gray-400 text-center">No posts yet uploaded.</div>
                </div>
              )
            )}
            
            {loadingMore && (
              <div className="mt-8 mb-12 flex justify-center">
                <CircularProgress />
              </div>
            )}
            {/* {!loadingMore && !hasMore && posts.length > 0 && (
              <div className="mt-4 text-gray-400 text-center">No more Posts to load.</div>
            )} */}
          </>
        )}
      </div>
      {selectedPost && (
        <Modal post={selectedPost} postIds={selectedPost.id} onClose={closeModal} incrementCommentCount={incrementCommentCount} decrementCommentCount={decrementCommentCount} />
      )}

<ToastContainer /> 
    </>
  );
  
};

export default PostsGrid;




// import React, { useState, useEffect } from 'react';
// import { LikeIcon, ViewIcon ,MultiIcon  } from './icon';
// import Modal from './Postmodal/PostModal';
// import { PROFILEPHOTO_URL } from '../../../../Config/config';

// const PostsGrid = ({ posts }) => {
//   const [selectedPost, setSelectedPost] = useState(null);

//   const openModal = (post) => {
//     setSelectedPost(post);
//     localStorage.setItem('postId', post.id);
//   };

//   const closeModal = () => {
//     setSelectedPost(null);
//   };

//   return (
//     <>
//     <div className="grid grid-cols-3 gap-4 mt-4">
//       {posts && posts.length > 0 ? (
//         posts.map(post => (
//           <div
//             key={post.id}
//             className="relative flex bg-black/20 2xl:w-64 2xl:h-64 xl:w-64 xl:h-64 lg:w-56 lg:h-60 md:w-48 md:h-48 rounded-2xl cursor-pointer hover:scale-105 ease-in-out duration-500 "
//             onClick={() => openModal(post)}
//           >
//                <img src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`} alt="Post" className="object-cover  w-full h-full rounded-lg" />
              
//                {post.contents.length > 1 && (
//               <div className="text-white absolute top-2 right-2 ml-2 flex flex-col items-center">
//                 <div className="relative flex items-center justify-center">
//                   <MultiIcon />
//                 </div>
//               </div>
//             )}

//             <div className="text-white absolute bottom-0 left-0 mb-2 ml-2 flex flex-col items-center">
//                <div className="relative flex items-center justify-center">
//                     <ViewIcon />
//                </div>
//               <span className="text-white text-xs">{post.viewCount}</span>
//             </div>

//              <div className="text-white absolute bottom-0 right-0 mb-2 mr-2 flex flex-col items-center">
//                  <div className="relative flex items-center justify-center">
//                    <LikeIcon />
//                  </div>
//                <span className="text-white text-xs">{post.likeCount}</span>
//             </div>

//           </div>
//         ))
//       ) : (
//         <div className="text-white">No posts available.</div>
//       )}
//     </div>
//     {selectedPost && (
//                 <Modal post={selectedPost} onClose={closeModal} />
//             )}
//         </>
//     );
// };

// export default PostsGrid;


// return (
//   <>
//     <div className="relative">
//       {loading && posts.length === 0 ? (
//         <div className="mt-24 flex justify-center">
//           <CircularProgress />
//         </div>
//       ) : (
//         <>
//           <div className="grid grid-cols-3 gap-4 mt-4">
//             {posts.length > 0 ? (
//               posts.map((post, index) => (
//                 <div
//                   key={post.id}
//                   className="relative flex bg-black/20 2xl:w-64 2xl:h-64 xl:w-64 xl:h-64 lg:w-56 lg:h-60 md:w-48 md:h-48 rounded-2xl cursor-pointer hover:scale-105 ease-in-out duration-500"
//                   onClick={() => openModal(post)}
//                 >
//                    {loadingPosts[index] && (
//                     <div className="absolute inset-0 flex items-center justify-center z-10">
//                       <CircularProgress />
//                     </div>
//                   )}
//                   <img 
//                   src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`} 
//                   alt="Post" 
//                   className={`object-cover w-full h-full rounded-lg ${loadingPosts[index] ? 'hidden' : 'block'}`}
//                   onLoad={() => handleImageLoad(index)}
//                   />
                  
//                   {post.contents.length > 1 && (
//                     <div className="text-white absolute top2- right-2 ml-2 flex flex-col items-center">
//                       <div className="relative flex items-center justify-center">
//                         <MultiIcon />
//                       </div>
//                     </div>
//                   )}

//                   <div className="text-white absolute bottom-0 left-0 mb-2 ml-2 flex flex-col items-center">
//                     <div className="relative flex items-center justify-center">
//                       <ViewIcon />
                    
//                     </div>
//                     <span className="textwhite- text-xs">{post.viewCount}</span>
//                   </div>

//                   <div className="text-white absolute bottom-0 right-0 mb-2 mr-2 flex flex-col items-center">
//                     <div className="relative flex items-center justify-center">
//                       <LikeIcon />
//                     </div>
//                     <span className="text-white text-xs">{post.likeCount}</span>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               !loading && (
//                 <div className="text-gray-400 text-center w-full">No posts yet uploaded.</div>
//               )
//             )}
//           </div>
//           {loadingMore && (
//             <div className="mt-8 flex justify-center">
//               <CircularProgress />
//             </div>
//           )}
//           {!loadingMore && !hasMore && posts.length > 0 && (
//             <div className="mt-4 text-gray-400 text-center">No more Posts to load.</div>
//           )}
//         </>
//       )}
//     </div>
//     {selectedPost && (
//       <Modal post={selectedPost}  postIds={selectedPost.id}   onClose={closeModal} incrementCommentCount={incrementCommentCount} 
//       decrementCommentCount={decrementCommentCount}  />
//     )}
//   </>
// );

