import React, { useState, useEffect, useRef } from 'react';
import { AudioIcon, CreateIcon, HomeIcon1, MoreIcon, PostIcon, SearchIcon, VidsIcon } from "./icon";
import { useNavigate } from "react-router-dom";
import Smart from '../../../assets/images/SMART-GEAR.png'
import smartIcon from '../../../assets/images/SMART-registered-blue.png'
import CircularProgress from '@mui/material/CircularProgress';
import { PROFILEPHOTO_URL } from '../../../Config/config';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import HomeIcon from '@mui/icons-material/Home';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import MovieIcon from '@mui/icons-material/Movie';
import MicIcon from '@mui/icons-material/Mic';

const MdxlSidebar = ({user, loading, error}) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const moreButtonRef = useRef(null);

    const handleMoreClick = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };


    const handleNavigation = (path) => {
        navigate(path);
      };

      const handleprofile =  (userId) => {
        navigate(`/smartsocial/${userId}/`);
    };


    const handleBookmarkClick = () => {
      setIsDropdownOpen(false);
      navigate(`/smartsocial/${user.id}/saved/`);
    };

     useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        moreButtonRef.current &&
        !moreButtonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, moreButtonRef]);
  

  return (
    <div 
      className="flex flex-col justify-between bg-white/95 w-16 sm:w-20 md:w-20 lg:w-48 xl:w-80 mt-8 rounded-tr-xl border-2 border-black" 
      style={{ height: 'calc(100vh - 1.5rem)' }}
    >
      <ul className="text-black text-xl  cursor-pointer ">
      <li className="flex items-center  px-2 md:px-0 py-1.5 justify-center xl:justify-start lg:justify-start   ">
      <a href="/" className="flex items-center justify-between rtl:space-x-reverse">

          <img src={Smart} className='w-16 h-16 md:w-14 md:h-14 lg:h-16 lg:w-16 xl:h-16 xl:w-16 2xl:h-16 2xl:w-16 ' /> 
          <img  src={smartIcon} className='w-44 h-12 hidden md:hidden lg:block md:w-16 lg:w-28 lg:h-6 xl:w-44 xl:h-8 2xl:w-44 2xl:h-10' />
          </a>
        </li>
        <a href='/' className="flex items-center hover:bg-gray-400  border-t border-b border-black/40 px-4 py-4 justify-center xl:justify-start lg:justify-start   ">
          <HomeIcon style={{ fontSize: 35 , color :'#21379A' }} />
          <span href='/' className="px-4 hidden uppercase md:hidden lg:block text-company-color text-2xl font-bold">Home</span> 
        </a>
        {/* <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-t border-black ">
          <SearchIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Search</div>
        </li> */}
        <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-b border-black/40" onClick={() => handleNavigation("/smartsocial/vids")}>
        <MovieIcon style={{ fontSize: 34 ,  color :'#21379A' }} />
          <div className="px-4 hidden md:hidden uppercase lg:block text-company-color  text-2xl font-bold">Vids</div>
        </li>
        <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-b border-black/40" onClick={() => handleNavigation("/smartsocial/post")}>
        <InsertPhotoIcon style={{ fontSize: 34 ,  color :'#21379A' }}  />
          <div className="px-4 hidden md:hidden  uppercase lg:block text-company-color text-2xl font-bold">Post</div>
        </li>
        {/* <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-t border-black">
          <MicIcon style={{ fontSize: 32 ,  color :'#21379A' }} />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Audio</div>
        </li> */}
        {/* <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-t border-b border-black">
          <CreateIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Create</div>
        </li> */}
      </ul>
      <div className="mt-auto">

      <div className="flex pb-3 items-center border-t px-3 border-black/40 hover:bg-gray-400 cursor-pointer" onClick={() => handleprofile(user && user.id)}>
    <div className="flex w-10 h-10 bg-white rounded-full overflow-hidden mt-2 items-center justify-center">
    {loading ? (
    <div role="status">
      <CircularProgress />
      </div>
  ) : error ? (
    <div className="error">Error loading image</div>
  ) : (
    user && <img src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
  )}    </div>
    <div className="text-company-color uppercase text-2xl px-4  font-bold mt-2 items-center justify-center hidden md:hidden lg:block" >
        Profile
    </div>
</div>


<div className="flex pb-5 items-center border-t px-3 border-black/40 hover:bg-gray-400 cursor-pointer"  
onClick={handleMoreClick}
ref={moreButtonRef}>
<div className="flex  overflow-hidden mt-2 items-center justify-center ml-1">

  <MoreIcon />
  </div>
        <div className="text-company-color uppercase text-2xl px-4 font-bold mt-2 hidden md:hidden lg:block">
          More
        </div>
      </div>
    </div>

    {isDropdownOpen && (
        <div ref={dropdownRef} className="absolute left-24 bottom-14  w-64 h-48 bg-white  border border-gray-700 rounded-lg shadow-lg z-10">
          <ul className="py-1">
  <div className="flex items-center px-4 py-2   mt-2 hover:bg-gray-200 cursor-pointer" onClick={handleBookmarkClick}>
    <BookmarksIcon style={{ fontSize: 30 , color :'#21379A'}} />
    <li className="ml-5 text-3xl text-company-color font-semibold">Saved</li> 
  </div>
  <div className=' border-b border-black/40 mt-2'>

  </div>
</ul>

        </div> 
      )}
      </div>
    
  );
};

export default MdxlSidebar;
