import React, { useState } from "react";
import post from '../../../../assets/Homepageicon/gallery.png';
import gif from '../../../../assets/Homepageicon/photo.png';
import video from '../../../../assets/Homepageicon/youtube.png';
import audio from '../../../../assets/Homepageicon/voice-message.png';
import { useNavigate } from 'react-router-dom';
import Post from "./post";
import Vids from "./vids";


const SmartSocial = () => {
    const [activeTab, setActiveTab] = useState('video'); 

    const navigate = useNavigate();


  const handleSocial = () => {
    navigate(`/smartsocial`);
  };

  return (
    <div className="mt-12 mb-10 flex items-center justify-center">
      <div className="bg-gray-200/20 sm:w-[540px]  w-[360px]  rounded-xl ">
        <h1 className="flex justify-center mt-8 font-bold mx-10 font-roboto sm:text-xl text-lg text-center ">
        Connect, Create and Engage with
         Smart Social    
              </h1>
        <p  className="mt-6 font-roboto text-gray-600 font-normal  sm:text-md text-sm mx-2 flex flex-col items-center text-center" >
        Ready to connect, share and create? Dive into Smart Social engaging features in just some simple steps!     
           </p>

           <div className="mt-6  flex justify-center items-center     ">
            <div>
              {activeTab === 'post' && (
                <>
                  {/* <img src={gif} alt="Post Gif" className="  sm:w-[350px] sm:h-[350px]  w-[250px] h-[200px]" />
                  <p className="flex justify-center mt-0">Post Gif</p> */}
                  <Post />
                </>
              )}
              {activeTab === 'video' && (
                <>
                  {/* <img src={gif} alt="Video Gif" className="sm:w-[350px] sm:h-[350px]  w-[250px] h-[200px]" />
                  <p className="flex justify-center mt-0">Video Gif</p> */}
                  <Vids />
                </>
              )}
              {/* {activeTab === 'audio' && (
                <>
                  <img src={gif} alt="Audio Gif" className="sm:w-[350px] sm:h-[350px]  w-[250px] h-[200px]" />
                  <p className="flex justify-center mt-0">Audio Gif</p>
                </>
              )} */}
            </div>
          </div>

<div className="flex ">
  
        <div className=" sm:mx-6 mx-2.5  justify-center">
           {/* Video */}

        <button
              onClick={() => setActiveTab('video')}
              className={`flex mt-8 ml-0 text-left px-4 py-4 cursor-pointer ${activeTab === 'video' ? 'bg-gray-400/50 rounded-xl' : 'hover:bg-gray-400/50 hover:rounded-xl'}`}
            >        <img src={video} alt="video" className="w-16 h-16  " />
        <div className="ml-8 text-lg  font-bold font-roboto">
        Create Vids
        <p className="font-roboto text-gray-600 font-normal sm:text-sm text-xs mt-1.5">
        Produce fun and entertaining videos to capture your daily moments and keep your audience engaged.  

           </p>
        </div>
        </button>

        <button
              onClick={() => setActiveTab('post')}
              className={`flex items-center mt-8 ml-0 mx-0 text-left px-4 py-4 cursor-pointer ${activeTab === 'post' ? 'bg-gray-400/50 rounded-xl' : 'hover:bg-gray-400/50 hover:rounded-xl'}`}
            >        <img src={post} alt="post" className=" w-16 h-16  " />
        <div className="ml-8 text-lg font-bold font-roboto">
        Upload Posts
        <p className="font-roboto text-gray-600 font-normal sm:text-sm text-xs mt-1.5">
        Share your thoughts and connect with friends through engaging posts.
        </p>
        </div>
        </button>

       

            {/* Audio */}
{/* 
            <button
              onClick={() => setActiveTab('audio')}
              className={`flex mt-8 ml-0 mb-8 text-left px-4 py-4 cursor-pointer ${activeTab === 'audio' ? 'bg-gray-400/50 rounded-xl' : 'hover:bg-gray-400/50 hover:rounded-xl'}`}
            >        <img src={audio} alt="audio" className="w-16 h-16 " />
        <div className="ml-8 text-lg font-bold font-roboto">
        Join Audio Chats
        <p className="font-roboto text-gray-600 font-normal sm:text-sm text-xs mt-1.5">
        Create or join chat rooms to connect with friends or meet new people and engage in lively discussions.     

                 </p>
  </div>
        </button> */}
        </div>
             
             
        </div>
        <div className="flex justify-center mb-10 mt-8">
        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-2xl text-md px-12 py-2.5 text-center inline-flex items-center " onClick={handleSocial}>
Smart Social
<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</button>
</div>
      </div>
    </div>
  );
};

export default SmartSocial;
