import React from "react";
import home from '../../../assets/Homepageicon/house.png';
import list from '../../../assets/Homepageicon/list.png';
import filter from '../../../assets/Homepageicon/filter.png';
import { useNavigate } from 'react-router-dom';


const RealEstate = () => {
  const navigate = useNavigate();


  const handleReal = () => {
    navigate(`/realestate`);
  };

  return (
    <div className="mt-6 mb-10 flex items-center justify-center">
      <div className="bg-gray-200/20 sm:w-[540px]  w-[360px]  mx-8 rounded-xl">
        <h1 className="flex justify-center mt-8 font-bold font-roboto sm:text-xl text-lg mx-2 text-center">
        Smartly Discover Your Perfect Home        </h1>
        <p  className="mt-6 font-roboto text-gray-600 font-normal sm:text-md text-sm mx-2 flex flex-col items-center text-center" >
        Looking for your dream home? Explore our intuitive real estate feature in just some simple steps!
        </p>

        <div className=" mt-16 mb-4">

        <div className=" flex flex-col items-center  px-1 text-center mt-0">
  <img src={home} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Start with Your Search</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Search for your desired location using our search bar. With map-based boundary search, move the map to find locations based on your preferences.

  </p>
</div>

<div className=" flex flex-col items-center  px-1 text-center mt-6">
  <img src={list} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">View Properties</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Choose between a list view for detailed property listings or a map view to see properties in your preferred location.

  </p>
</div>

<div className=" flex flex-col items-center  px-1 text-center mt-6">
  <img src={filter} alt="real estate" className="sm:w-10 sm:h-10 w-8 h-8 mb-2 " />
  <h2 className="font-bold text-lg font-roboto">Filter and Sort Results</h2>
  <p className="mt-1 font-roboto text-gray-600 font-normal text-sm">
  Refine your search using our sort by and filter by features to find properties that meet your specific criteria (e.g., price, number of bedrooms, etc.).  

  </p>
</div>




        </div>
        <div className="flex justify-center mb-10 mt-8">
        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-2xl text-md px-10 py-2 text-center inline-flex items-center " onClick={handleReal}>
Real Estate
<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</button>
</div>
      </div>
    </div>
  );
};

export default RealEstate;
