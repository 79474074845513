import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';
import { CircularProgress } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const Vids = () => {
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const fetchVideos = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_URL}/vids/v2/public-vids`,
        headers: {},
      };

      try {
        const response = await axios.request(config);
        setVideos(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    if (videos.length > 0) {
      const interval = setInterval(() => {
        setIsVideoLoading(true); 
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
      }, 20000);

      return () => clearInterval(interval);
    }
  }, [videos]);

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const toggleMute = () => {
    if (videoRef.current) {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    }
};

  if (loading) {
    return (
      <div className="flex items-center justify-center 2xl:w-[350px] 2xl:h-[500px] xl:w-[350px] xl:h-[500px] lg:w-[350px] lg:h-[500px] md:w-[300px] md:h-[450px] mt-4">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (videos.length === 0) {
    return <p>No videos available</p>;
  }

  const currentVideo = videos[currentVideoIndex];

  return (
    <div className="flex flex-col 2xl:w-[350px] 2xl:h-[500px] xl:w-[350px] xl:h-[500px] lg:w-[350px] lg:h-[500px] md:w-[300px] md:h-[450px] mt-4">
      <div key={currentVideo.id} className="flex bg-gray-200 flex-col mb-1 rounded-xl shadow-2xl">
        <div className="flex  mt-0 relative">
          <div className="2xl:h-[500px] xl:h-[500px] lg:h-[500px] md:h-[400px] w-full flex justify-center items-center rounded-xl shadow-2xl overflow-hidden">
            {isVideoLoading && (
              <div className="absolute z-10 bg-white bg-opacity-50 w-full h-full flex justify-center items-center">
                <CircularProgress />
              </div>
            )}
            <video
              src={`${PROFILEPHOTO_URL}/${currentVideo.url}`}
              ref={videoRef}

              className="object-cover w-full h-full"
              muted={isMuted}
              autoPlay
              loop
              onLoadedData={handleVideoLoad} 
            />
          </div>
          <div className="absolute bottom-10 ml-4 flex flex-col space-y-1">
  <div className="flex items-center">
    <div className="bg-black rounded-full w-10 h-10 overflow-hidden">
      <img
        src={`${PROFILEPHOTO_URL}/${currentVideo.user.profilePhoto}`}
        alt="Profile"
        className="object-cover w-full h-full"
      />
    </div>
    <span className="ml-2 text-white font-semibold">
      {currentVideo.user.firstName} {currentVideo.user.lastName}
    </span>
  </div>

  {currentVideo.caption && (
    <div className="text-white text-sm mt-2 ml-2">
      <span>
        {currentVideo.caption.length > 30
          ? `${currentVideo.caption.slice(0, 30)}...`
          : currentVideo.caption}
      </span>
    </div>
  )}
</div>

                {/* <button
                    className="absolute top-4 right-4 bg-gray-800/70 hover:bg-gray-700/70 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg"
                    onClick={(event) => { event.stopPropagation(); toggleMute(); }}
                >
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button> */}
                <div className="absolute bottom-0 right-3 flex flex-col items-center gap-5 mb-10"> 
               <div className="text-white flex flex-col items-center">
    <div className="relative flex items-center justify-center">
      <VisibilityOutlinedIcon style={{ color: 'white' ,fontSize: 28 }} />
    </div>
    <span className="text-white text-sm ">{currentVideo.viewCount}</span>
  </div>  
  <div className="text-white flex flex-col items-center " >
    <div className="relative flex items-center justify-center">
      <FavoriteBorderIcon  style={{ fontSize: 28,  }} />
    </div>
    <span className="text-white text-sm ">{currentVideo.likeCount}</span>
  </div>
  <div className="text-white flex flex-col items-center "
      > 
    <div className="relative flex items-center justify-center">
    <ChatBubbleOutlineIcon style={{ fontSize: 23 }} />
    </div>
    <span className="text-white text-sm ">{currentVideo.commentCount}</span>
  </div>
  <div className="text-white flex flex-col items-center "
      > 
    <div className="relative flex items-center justify-center">
    <BookmarkBorderOutlinedIcon style={{ fontSize: 25 }} />
    </div>
    <span className="text-white text-sm ">{currentVideo.bookmarkCount}</span>
  </div>
   </div>

   
   </div>
  
       
      </div>
    </div>
  );
};

export default Vids;
