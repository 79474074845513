// import React from 'react';


// import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
// import Navs from '../../assets/images/navigator.png'

// const libraries = ['places'];

//     const CustomMap = ({ data }) => {
      
//         const center = {
//           lat: data.address.lat,
//           lng: data.address.lon
//         };

        
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: 'AIzaSyAMIDx-RNcY6GOPPMnjOQbZDFzWb1oQSvY',
//     libraries,
//   });

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }


//   return (
//     <div >
//       <div className="w-60 h-36   hidden md:block    mt-12 ">
//         <GoogleMap 
//           mapContainerStyle={{ width: '100%', height: '100%' , borderRadius: '15px'}}
//           zoom={15}
//           center={center}
//         >
//           <Marker 
//           position={center}
          
//           // icon={{
//           //   url: Navs,
//           //   scaledSize:{
//           //     width:40,
//           //     height:40
//           //   }
//           // }}
//           />
//         </GoogleMap>
//       </div>
//     </div>
//   );
// };

// export default CustomMap;


// import React, { useState } from 'react';
// import { GoogleMap, useLoadScript, Marker, StreetViewPanorama } from '@react-google-maps/api';

// const libraries = ['places'];

// const CustomMap = ({ data }) => {
//   const center = {
//     lat: data.address.lat,
//     lng: data.address.lon
//   };

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: 'AIzaSyAMIDx-RNcY6GOPPMnjOQbZDFzWb1oQSvY',
//     libraries,
//   });

//   const [isStreetViewVisible, setStreetViewVisible] = useState(false);

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }

//   return (
//     <div className="relative"> 
//       <div className="w-60 h-36 hidden md:block mt-12">
//         <GoogleMap
//           mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '15px' }}
//           zoom={15}
//           center={center}
//           options={{
//             fullscreenControl: true,
//           }}
//         >
//           <Marker position={center} />
//           {isStreetViewVisible && (
//             <StreetViewPanorama
//               position={center}
//               visible={isStreetViewVisible}
//               onCloseClick={() => setStreetViewVisible(false)}
//             />
//           )}
//         </GoogleMap>
//       </div>

   
//       <button
//         className="absolute bottom-2 right-1 bg-white border rounded p-2 shadow"
//         onClick={() => setStreetViewVisible(!isStreetViewVisible)}
//       >
//        Street View
//       </button>
//     </div>
//   );
// };

// export default CustomMap;


import React, { useState, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker, StreetViewPanorama } from '@react-google-maps/api';
import Navs from '../../assets/images/navigator.png';

const libraries = ['places'];

const CustomMap = ({ data }) => {
  const center = {
    lat: data.address.lat,
    lng: data.address.lon
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAMIDx-RNcY6GOPPMnjOQbZDFzWb1oQSvY',
    libraries,
  });

  const [isStreetViewVisible, setStreetViewVisible] = useState(false);
  const mapRef = useRef(null); 

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const toggleStreetView = () => {
    setStreetViewVisible(!isStreetViewVisible);

    if (!isStreetViewVisible && mapRef.current) {
    
      const map = mapRef.current;
      const fullscreenControl = map.getDiv().querySelector('.gm-fullscreen-control');
      if (fullscreenControl) {
        fullscreenControl.click(); 
      }
    }
  };

  return (
    <div className="relative"> 
      <div className="w-60 h-36 hidden md:block mt-12">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '15px' }}
          zoom={15}
          center={center}
          options={{
            fullscreenControl: true,
          }}
          onLoad={(map) => (mapRef.current = map)}
        >
          <Marker position={center} />
          {isStreetViewVisible && (
            <StreetViewPanorama
              position={center}
              visible={isStreetViewVisible}
              onCloseClick={() => setStreetViewVisible(false)}
            />
          )}
        </GoogleMap>
      </div>

   
      <button
        className="absolute bottom-4 right-1 bg-white border rounded p-2 shadow hidden md:block"
        onClick={toggleStreetView}
        >
          {isStreetViewVisible ? 'Exit Street View' : 'Street View'}
        </button>
    </div>
  );
};

export default CustomMap;
