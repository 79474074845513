import React, { useState, useEffect } from "react";
import smartGearImage from '../../../assets/images/SMART-GEAR.png';
import qrcode from '../../../assets/images/qr-code.png';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';

const Appnotification = () => {
  const [isLabelVisible, setIsLabelVisible] = useState(false);

  const toggleLabelVisibility = () => {
    setIsLabelVisible(!isLabelVisible);
  };

  
  return (
    <div>
       <div className={`z-10 fixed right-0 bottom-24 bg-gray-400/50 w-[300px] h-24 rounded-l-xl flex items-center  transition-transform duration-300 ease-in-out 
                      ${isLabelVisible ? 'right-0 translate-x-0' : 'right-0 translate-x-full'}`}>
         <div >
          <img src={smartGearImage} alt="gear" className="w-16 h-16" />
        </div>
        <div className="text-company-color  font-bold font-robot ml-3">
           Download The
           <br />
            Smart App
           </div>

           <div className="ml-2 ">
           <img src={qrcode} alt="qrcode" className="w-16 h-16" />

           </div>
           <button onClick={toggleLabelVisibility} className="absolute top-2 right-2 text-white ml-2">
          <CloseOutlinedIcon size={20} /> 
        </button>
       </div>
       {!isLabelVisible && (
        <button onClick={toggleLabelVisibility} className="fixed right-0 bottom-24 bg-gray-400/50 w-6 h-24 rounded-l-lg flex items-center justify-center text-white">
          <NavigateBeforeOutlinedIcon size={16} /> 
        </button>
      )}
    </div>
  );
};

export default Appnotification;
