import React, { useEffect, useState } from 'react';
import axios from 'axios';
import post from '../../../../assets/Homepageicon/gallery.png';
import gif from '../../../../assets/Homepageicon/photo.png';
import video from '../../../../assets/Homepageicon/youtube.png';
import audio from '../../../../assets/Homepageicon/voice-message.png';
import { useNavigate } from 'react-router-dom';
import Post from './post';
import Vids from './vids';

const SmartSocial = () => {
    const [activeTab, setActiveTab] = useState('video'); 

  
    const navigate = useNavigate();


  const handleSocial = () => {
    navigate(`/smartsocial`);
  };

  

  return (
    <div className="mt-12 mb-10 flex items-center justify-center">
      <div className="bg-gray-200/20 2xl:w-[1150px] xl:w-[1150px] lg:w-[950px] md:w-[800px]   rounded-xl ">
        <h1 className="flex justify-center mt-8 font-bold mx-10 font-roboto 2xl:text-5xl xl:text-5xl  lg:text-4xl md:text-3xl text-center ">
        Connect, Create and Engage with
         Smart Social    
              </h1>
        <p  className="mt-6 font-roboto text-gray-600 font-normal 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl mx-4 flex flex-col items-center text-center" >
        Ready to connect, share and create? Dive into smart social engaging features in just some simple steps!        </p>

<div className="flex">
  {/* left side section */}



            {/* Post */}
        <div className="2xl:w-1/2 xl:w-1/2 lg:w-[520px] md:w-[500px] ">

          {/* Video */}

<button
              onClick={() => setActiveTab('video')}
              className={`flex mt-28 ml-14 text-left px-4 py-4 cursor-pointer ${activeTab === 'video' ? 'bg-gray-400/50 rounded-xl' : 'hover:bg-gray-400/50 hover:rounded-xl'}`}
            >        <img src={video} alt="video" className="2xl:w-28 2xl:h-28 xl:w-28 xl:h-28 lg:w-24 lg:h-24 md:w-16 md:h-16  " />
        <div className="ml-12 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg font-bold font-roboto">
        Create Vids
        <p className="font-roboto text-gray-600 font-normal 2xl:w-[260px] xl:w-[260px] lg:w-[260px] md:w-[200px] 2xl:text-md xl:text-md lg:text-sm md:text-xs mt-1.5">
       
        Produce fun and entertaining videos to capture your daily moments and keep your audience engaged.  

           </p>
        </div>
        </button>

        <button
              onClick={() => setActiveTab('post')}
              className={`flex mt-8 ml-14 text-left px-4 py-4 cursor-pointer ${activeTab === 'post' ? 'bg-gray-400/50 rounded-xl' : 'hover:bg-gray-400/50 hover:rounded-xl'}`}
            >        <img src={post} alt="post" className="2xl:w-28 2xl:h-28 xl:w-28 xl:h-28 lg:w-24 lg:h-24 md:w-16 md:h-16  " />
        <div className="ml-12 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg font-bold font-roboto">
        Upload Posts
        <p className="font-roboto text-gray-600 font-normal 2xl:w-[260px] xl:w-[260px] lg:w-[260px] md:w-[200px] 2xl:text-md xl:text-md lg:text-sm md:text-xs mt-1.5">
        Share your thoughts and connect with friends through engaging posts.
        </p>
        </div>
        </button>

        

            {/* Audio */}

            {/* <button
              onClick={() => setActiveTab('audio')}
              className={`flex mt-8 ml-14 mb-8 text-left px-4 py-4 cursor-pointer ${activeTab === 'audio' ? 'bg-gray-400/50 rounded-xl' : 'hover:bg-gray-400/50 hover:rounded-xl'}`}
            >        <img src={audio} alt="audio" className="2xl:w-28 2xl:h-28 xl:w-28 xl:h-28 lg:w-24 lg:h-24 md:w-16 md:h-16 " />
        <div className="ml-12 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg font-bold font-roboto">
        Join Audio Chats
        <p className="font-roboto text-gray-600 font-normal 2xl:w-[260px] xl:w-[260px] lg:w-[260px] md:w-[200px] 2xl:text-md xl:text-md lg:text-sm md:text-xs mt-1.5">
        Create or join chat rooms to connect with friends or meet new people and engage in lively discussions.     

 </p>
  </div>
        </button> */}
        </div>
              {/* Right side section */}
              <div className="mt-0 ml-10 flex justify-center items-center w-1/2">
            <div>
              {activeTab === 'post' && (
                <>
                <Post />
                  {/* <img src={gif} alt="Video Gif" className=" mt-6 2xl:w-96 xl:w-96 lg:w-80 md:w-80 2xl:h-[420px]  xl:h-[420px] lg:h-[400px] md:h-[360px]" /> */}

                  {/* <p className="flex justify-center mt-0">Post Gif</p> */}
                </>
              )}
              {activeTab === 'video' && (
                <>
                  {/* <img src={gif} alt="Video Gif" className="mt-6 2xl:w-96 xl:w-96 lg:w-80 md:w-80 2xl:h-[420px]  xl:h-[420px] lg:h-[400px] md:h-[360px]" />
                  <p className="flex justify-center mt-0">Video Gif</p> */}
                  <Vids />
                </>
              )}
              {/* {activeTab === 'audio' && (
                <>
                  <img src={gif} alt="Audio Gif" className="2xl:w-96 xl:w-96 lg:w-80 md:w-80 2xl:h-[420px]  xl:h-[420px] lg:h-[400px] md:h-[360px]" />
                  <p className="flex justify-center mt-0">Audio Gif</p>
                </>
              )} */}
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-10 mt-8">
        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-2xl text-md px-12 py-2.5 text-center inline-flex items-center " onClick={handleSocial}>
Smart Social
<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</button>
</div>
      </div>
    </div>
  );
};

export default SmartSocial;
