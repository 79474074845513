import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL , PROFILEPHOTO_URL } from "../../../../Config/config";
import { CircularProgress } from '@mui/material'; 
import { toast, ToastContainer , Slide} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import Modal from '../../UserProfile/Smscreen/postmodal/postmodal';


const SavedPostGrid = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false); 
  const [page, setPage] = useState(0); 
  const [hasMore, setHasMore] = useState(true); 
  const [loadingPosts, setLoadingPosts] = useState([]);
  const [hasShownToast, setHasShownToast] = useState(false); 

  useEffect(() => {
    const fetchSavedPosts = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token not found in local storage');
        setLoading(false);
        return;
      }

      try {
        const config = {
          method: 'get',
          url: `${API_URL}/posts/v3/bookmark?page_number=${page}`,
          headers: {
            'Authorization': `Bearer ${token}`
          },
        };

        setLoadingMore(true); 

        const response = await axios.request(config);
        const newPosts = response.data.data;

      
        setPosts(prevPosts => [...prevPosts, ...newPosts]);        
        setLoadingPosts(prevLoadingPosts => [...prevLoadingPosts, ...new Array(newPosts.length).fill(true)]);

        setHasMore(newPosts.length > 0);

        if (newPosts.length > 0) {
          setHasShownToast(false);
        }
        
        if (document.documentElement.scrollHeight <= window.innerHeight && newPosts.length > 0) {
          setPage((prevPage) => prevPage + 1);
        }
        
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false); 
        setLoadingMore(false); 
      }
    };

    fetchSavedPosts();
  }, [page]);

  const handleImageLoad = (index) => {
    setLoadingPosts((prevLoadingPosts) => {
      const newLoadingPosts = [...prevLoadingPosts];
      newLoadingPosts[index] = false;
      return newLoadingPosts;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2;

    
      if (isAtBottom) {
      
        if (hasMore && !loadingMore) {
          setPage(prevPage => prevPage + 1);
        } 
       
        else if (!hasMore && posts.length > 0 && !hasShownToast) {
          toast.info('No More Posts to load.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            transition: Slide, 
            theme: "colored",
          });
          setHasShownToast(true); 
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loadingMore, posts.length, hasShownToast]); 



  const openModal = (post) => {
    setSelectedPost(post);
    localStorage.setItem('postId', post.id);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  if (loading) {
    return <div className="mt-24 flex justify-center"><CircularProgress /></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="relative mt-8">
        {posts.length > 0 ? (
          <div className="grid grid-cols-3 gap-4 mt-4">
            {posts.map((post, index) => (
              <div key={post.id} 
              className="relative flex bg-black/20 sm:w-52 sm:h-48 w-32 h-36 rounded-2xl cursor-pointer hover:scale-105 ease-in-out duration-500 overflow-auto"
              onClick={() => openModal(post)}
              >
                {loadingPosts[index] && (
                  <div className="absolute inset-0 flex items-center justify-center z-10">
                    <CircularProgress />
                  </div>
                )}
                <img 
  src={post.contents && post.contents[0] ? `${PROFILEPHOTO_URL}/${post.contents[0].url}` : ''} 
  alt="Post" 
                  className={`object-cover w-full h-full rounded-lg ${loadingPosts[index] ? 'hidden' : 'block'}`}
                  onLoad={() => handleImageLoad(index)}
                />
  {post.contents && post.contents.length > 1 && (                  <div className="text-white absolute top-2 right-2 ml-2 flex flex-col items-center">
                    <div className="relative flex items-center justify-center">
                      <PhotoLibraryOutlinedIcon style={{ color: 'white' ,fontSize: 25 }} />
                    </div>
                  </div>
                )}
                <div className="text-white absolute bottom-0 left-0 mb-2 ml-2 flex flex-col items-center">
                  <div className="relative flex items-center justify-center">
                    <VisibilityOutlinedIcon style={{ color: 'white' ,fontSize: 31 }} />
                  </div>
                  <span className="text-white text-xs">{post.viewCount}</span>
                </div>
                <div className="text-white absolute bottom-0 right-0 mb-2 mr-2 flex flex-col items-center">
                  <div className="relative flex items-center justify-center">
                    <FavoriteBorderOutlinedIcon style={{ color: 'white' ,fontSize: 31 }} />
                  </div>
                  <span className="text-white text-xs">{post.likeCount}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center h-96 w-full">
            <div className="text-gray-400 text-center">No saved posts available</div>
          </div>
        )}
         {loadingMore && (
              <div className="mt-8 mb-12 flex justify-center">
                <CircularProgress />
              </div>
            )}
      </div>
      {selectedPost && (
        <Modal post={selectedPost} postIds={selectedPost.id} onClose={closeModal}  />
      )}

<ToastContainer /> 

    </>
  );
};

export default SavedPostGrid;
