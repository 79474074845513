import React from "react";
import home from '../../../assets/Homepageicon/house.png';
import list from '../../../assets/Homepageicon/list.png';
import filter from '../../../assets/Homepageicon/filter.png';
import { useNavigate } from 'react-router-dom';


const RealEstate = () => {
  const navigate = useNavigate();


  const handleReal = () => {
    navigate(`/realestate`);
  };

  return (
    <div className="mt-6 mb-10 flex items-center justify-center">
      <div className="bg-gray-200/20 2xl:w-[1150px] xl:w-[1150px] lg:w-[950px] md:w-[800px]  mx-8 rounded-xl">
        <h1 className="flex justify-center mt-8 font-bold font-roboto 2xl:text-5xl xl:text-5xl  lg:text-4xl md:text-3xl text-center">
        Smartly Discover Your Perfect Home        </h1>
        <p  className="mt-6 font-roboto text-gray-600 font-normal 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl flex flex-col items-center text-center" >
        Looking for your dream home? Explore our intuitive real estate feature in just some simple steps!
        </p>

        <div className="flex flex-row items-center mt-24 mb-4">
        <div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2 text-center mt-6">
  <img src={home} alt="real estate" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4 " />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto ">Start with Your Search</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Search for your desired location using our search bar. With map-based boundary search, move the map to find locations based on your preferences.
  </p>
</div>

<div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2  text-center mt-6 ">
  <img src={list} alt="camera" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4" />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">View Properties </h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Choose between a list view for detailed property listings or a map view to see properties in your preferred location.
  </p>
</div>

<div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2  text-center mt-6 ">
  <img src={filter} alt="camera" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4" />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Filter and Sort Results</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Refine your search using our sort by and filter by features to find properties that meet your specific criteria (e.g., price, number of bedrooms, etc.).  </p>
</div>

        </div>
        <div className="flex justify-center mb-10 mt-8">
        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-2xl text-md 2xl:px-12 2xl:py-2.5 xl:px-12 xl:py-2.5 lg:px-10 lg:py-2 md:px-10 md:py-2 text-center inline-flex items-center " onClick={handleReal}>
Real Estate
<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</button>
</div>
      </div>
    </div>
  );
};

export default RealEstate;
