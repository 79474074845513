import React from "react";
import Horoscope from '../../../assets/Homepageicon/horoscope.png';
import Weather from '../../../assets/Homepageicon/meteorology.png';
import card from '../../../assets/Homepageicon/id-card.png';


const Extrafeatures = () => {
  return (
    <div className="mt-6 mb-10 flex items-center justify-center ">
      <div className="bg-gray-200/30 2xl:w-[1150px] xl:w-[1150px] lg:w-[950px] md:w-[800px]  mx-8 rounded-xl mb-0">
        <h1 className="flex justify-center mt-8 font-bold font-roboto 2xl:text-5xl xl:text-5xl  lg:text-4xl md:text-3xl text-center">
        Explore More with Smart Extras        </h1>

        <div className="flex flex-row items-center mt-24 mb-8">
        <div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2 text-center mt-0">
  <img src={Horoscope} alt="real estate" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4 " />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Horoscope</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Discover your horoscope  check your daily horoscope for insights and guidance.
  </p>
</div>

<div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2  text-center mt-0 ">
  <img src={Weather} alt="camera" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4" />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Weather</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Stay updated with weather get real-time weather updates to plan your day effectively.  </p>
</div>

<div className="flex flex-col items-center w-1/2 2xl:px-4 xl:px-4 lg:px-4 md:px-2  text-center mt-6 ">
  <img src={card} alt="camera" className="2xl:w-12 2xl:h-12 xl:w-12 xl:h-12 lg:w-12 lg:h-12 md:w-10 md:h-10 mb-4" />
  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl font-roboto">Business Card</h2>
  <p className="mt-2 font-roboto text-gray-600 font-normal 2xl:text-md xl:text-md lg:text-sm md:text-xs">
  Create your digital business card design and share your professional business card effortlessly.  </p>
</div>

        </div>
      </div>
    </div>
  );
};

export default Extrafeatures;
