// import React, { useState } from "react";
// import ReactPlayer from "react-player";
// import { YOUTUBE_VIDEO_LINK } from "../../../Config/config";

// const Videosection = () => {

//   const videoUrls = [
//     "https://www.youtube.com/watch?v=zxOXBDqB5go",
//     "https://www.youtube.com/watch?v=pfNZaNP9PCE",
//   ];

//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

//   const handleVideoEnd = () => {
//     setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
//   };
  
//   return (
//     <div className="flex justify-center">

   
//     <div className="flex justify-center  items-center mt-24 sm:w-[540px] sm:h-80 w-[360px] h-[250px] border border-black shadow-xl mb-10 rounded-xl overflow-hidden">
//       <ReactPlayer
//         url={videoUrls[currentVideoIndex]}
//         // url={`${YOUTUBE_VIDEO_LINK}`}
//         // url="https://www.youtube.com/watch?v=zxOXBDqB5go"
//         playing
//         controls
//         loop={false}      
//         width="100%"
//         height="100%"
//         // onClickPreview={(e) => e.target.play()} 
//         muted
//         onEnded={handleVideoEnd} 
//       />
//     </div>
//     </div>
//   );
// };

// export default Videosection;

import React, { useState } from "react";
import ReactPlayer from "react-player";
import { YOUTUBE_VIDEO_LINK } from "../../../Config/config";

const Videosection = () => {
  
  const videoUrls = [
    "https://www.youtube.com/watch?v=zxOXBDqB5go",
    "https://www.youtube.com/watch?v=pfNZaNP9PCE",
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
  };

  return (
    <div className="flex justify-center items-center w-full mt-[85px] sm:mt-[80px] h-[calc(40vh-79px)] sm:h-[calc(100vh-79px)]  aspect-video border border-black shadow-xl mb-10 overflow-hidden">
      <ReactPlayer
        url={videoUrls[currentVideoIndex]}
        playing
        controls
        loop={false}
        width="100%"
        height="100%"
        muted
        onEnded={handleVideoEnd}
      />
    </div>
  );
};

export default Videosection;
